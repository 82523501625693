import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import UserList from './components/UserList';
import UserDetails from './components/UserDetails';

export default function Users({ status }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [reload, setReload] = useState();
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState(
    location.hash && location.hash.length > 1
      ? location.hash.substring(1, location.hash.length)
      : null
  );

  const handleClose = () => {
    setReload(new Date());
    navigate(-1);
  };

  React.useEffect(() => {
    if (location.hash !== userId) {
      setUserId(location.hash);
      if (location.hash && location.hash.length > 1) {
        setUserId(location.hash.substring(1, location.hash.length));
      } else {
        setUserId(null);
      }
    }
  }, [location.hash, userId]);

  React.useEffect(() => {
    if (userId && userId.length > 1) {
      setShow(true);
    } else {
      console.log('no user id');
      setShow(false);
    }
  }, [userId]);

  return (
    <>
      <div className="page-content users">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-md-12 col-xl-3">
              <h1>
                <i className="fa-light fa-user me-2"></i>
                Users
              </h1>
            </div>
          </div>

          <UserList status={status} location={location} reload={reload} />

          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-dialog-centered modal-xl modal-fullscreen-xl-down"
          >
            <UserDetails id={userId} key={userId} onDone={handleClose} />
          </Modal>
        </div>
      </div>
    </>
  );
}

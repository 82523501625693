import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { saveCustomer, updateCustomer } from '../../../actions/customerActions';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

export default function EditCustomer({ customer, onDone }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    updateCustomer({
      customerId: customer.customerId,
      customerName: data.customerName,
      cssClass: customer.cssClass,
      logoBlobId: customer.logoBlobId,
      logoThumbnailBlobId: customer.logoThumbnailBlobId,
    }).then(() => {
      toast.success('Customer saved');
      onDone();
    });
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-light fa-building me-2"></i> Edit Customer
        </Modal.Title>
      </Modal.Header>
      <div className="addCustomer mt-3 mb-3">
        <div className="container-fluid">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body">
                <h2>Customer Details</h2>
                <div className="form-group row g-0">
                  <label className="col-4">
                    <i className="fa-light fa-hashtag me-2 blue"></i>
                    Customer name
                  </label>
                  <div className="col-8">
                    <input
                      type="text"
                      required
                      autoComplete="off"
                      className="form-control"
                      placeholder="customer name"
                      defaultValue={customer.customerName}
                      {...register('customerName', {})}
                    />
                    {errors.customerName && (
                      <span className="field-validation-error">Required</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col mt-3">
                <input type="submit" className="btn btn-primary float-end" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

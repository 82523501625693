import {
  apiUrl,
  deleteRecord,
  download,
  get,
  getAll,
  getBase64,
  getList,
  getSingleRecord,
  saveRecord,
  upload,
} from './apiUtils';
const baseUrl = apiUrl + '/jobAttachments/';

export function getAttachmentsForJob(
  id,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}job/${id}`;
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getAllAttachmentsForJob(id) {
  let url = `${baseUrl}job/${id}`;
  return getAll(url);
}

export function getAttachmentById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveAttachment(attachment) {
  return saveRecord(baseUrl, attachment);
}

export function uploadAttachment(attachment) {
  return upload(baseUrl, attachment);
}

export function deleteAttachment(id) {
  return deleteRecord(baseUrl, id);
}

export function downloadAttachment(id) {
  return download(`${baseUrl}${id}/download`);
}

export function getAttachmentBase64(id) {
  return getBase64(`${baseUrl}${id}/download`);
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { signinSilentCallback } from '../../services/userService';
import { tokenRefreshed } from '../../actions/authActions';
import Cookies from 'universal-cookie';

function SilentRenewOidc({ tokenRefreshed }) {
  useEffect(() => {
    signinSilentCallback().then((user) => {
      const cookies = new Cookies();
      cookies.set('token', user.access_token);
      tokenRefreshed(user.access_token);
    });
  }, [tokenRefreshed]);
  return <div>Silently refreshed token...</div>;
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  tokenRefreshed,
};

export default connect(mapStateToProps, mapDispatchToProps)(SilentRenewOidc);

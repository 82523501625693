import React, { useState } from 'react';
import { loadUsers } from '../../../actions/userActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import useTableSettings from '../../../hooks/useTableSettings';

function UserList({ dateRange, status, location, reload }) {
  const [visibleColumns] = useState([
    'fullName',
    'emailAddress',
    'mobileNumber',
    'customerName',
    'username',
  ]);

  const [users, setUsers] = useState();
  const params = new URLSearchParams(location.search);
  const [tableSettings, setTableSettings, filterChange, isLoading] =
    useTableSettings(
      'userlist-settings',
      getTableSettingsDefaults('fullName', location.search, 'asc'),
      null,
      loadUsers,
      setUsers,
      reload,
      'Failed to load users',
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'fullName',
      Label: 'Name',
      width: '20%',
      Render: (user) => <Link to={'#' + user.id}>{user.fullName}</Link>,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key="search"
            Value={params.get(`f_search`) || ''}
            onChange={(value) => filterChange('search', value)}
          />
        ),
      },
    },

    {
      Key: 'emailAddress',
      Label: 'Email',
      width: '20%',
      Render: (user) => user.emailAddress,
    },
    {
      Key: 'mobileNumber',
      Label: 'Mobile',
      width: '20%',
      Render: (user) => user.mobileNumber,
    },
    {
      Key: 'customerName',
      Label: 'Customer',
      width: '20%',
      Render: (user) => user.customerName,
    },
    {
      Key: 'username',
      Label: 'Username',
      width: '20%',
      Render: (user) => user.username,
    },
  ];

  // filter
  let columns = visibleColumns.map((x) =>
    columnsConfig.find((y) => y.Key === x)
  );

  // return a table
  return users ? (
    <div>
      <Table
        columns={columns}
        rows={users.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={users.totalRowCount}
        filteredRowCount={users.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
      />
    </div>
  ) : (
    <Loading></Loading>
  );
}

export default UserList;

export default {
  UNASSIGNED: 1,
  READY: 2,
  INPROGRESS: 3,
  NEEDSSECONDVISIT: 4,
  NEEDSRESCHEDULING: 5,
  COMPLETE: 6,
  NEEDSPRICING: 7,
  NEEDSINVOICING: 8,
  INVOICED: 9,
};

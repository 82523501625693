import Cookies from 'universal-cookie';
export async function handleResponse(response) {
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  } else if (response.status === 403) {
    //forbidden
    //TODO: handle 403
  }
  throw new Error();
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error('API call failed. ' + error);
  throw error;
}

export function apiOptions() {
  let cookie = new Cookies();
  let token = cookie.get('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const isMock = (baseUrl) => baseUrl.indexOf('localhost:3001') > -1;

//Couldn't get JSON server to route with /:id without picking up
//all GET queries, so had to implement this.
//Hopefully will find a fix, and can remove this everywhere in time.
function getSingleRecordUrl(baseUrl, id) {
  return !isMock(baseUrl) ? `${baseUrl}${id}` : `${baseUrl}?id=${id}`;
}

export function getSingleRecord(baseUrl, id) {
  return fetch(getSingleRecordUrl(baseUrl, id), apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

function getStatsUrl(baseUrl, id) {
  return !isMock(baseUrl)
    ? `${baseUrl}${id}/stats`
    : `${baseUrl}stats?id=${id}`;
}

export function getStatsData(baseUrl, id) {
  return fetch(getStatsUrl(baseUrl, id), apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

function getAuditUrl(baseUrl, id) {
  return !isMock(baseUrl)
    ? `${baseUrl}${id}/audit`
    : `${baseUrl}audit?id=${id}`;
}

export function getAuditData(baseUrl, id) {
  return fetch(getAuditUrl(baseUrl, id), apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function saveRecord(baseUrl, record) {
  return fetch(baseUrl + (record.id || ''), {
    method: record.id ? 'PUT' : 'POST', // POST for create, PUT to update when id already exists.
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
    body: JSON.stringify({
      ...record,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}

function getListUrl(baseUrl, filters, _scopes, _sort, _order, _page, _limit) {
  let url = `${baseUrl}${
    baseUrl.indexOf('?') > -1 ? '&' : '?'
  }_sort=${_sort}&_order=${_order}&_page=${_page + 1}&_limit=${_limit}`;
  if (filters) {
    url +=
      '&' +
      Object.keys(filters)
        .filter((key) => filters[key])
        .map((key) => `${key}_like=${filters[key]}`)
        .join('&');
  }
  if (_scopes) {
    url +=
      '&' +
      Object.keys(_scopes)
        .filter((key) => _scopes[key])
        .map((key) => `${key}_scope=${_scopes[key]}`)
        .join('&');
  }
  return url;
}

export function getList(
  baseUrl,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return fetch(
    getListUrl(baseUrl, filters, _scopes, _sort, _order, _page, _limit),
    apiOptions()
  )
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRecord(baseUrl, id, data) {
  if (data) {
    return fetch(baseUrl + id, {
      method: 'DELETE',
      headers: { ...apiOptions().headers, 'content-type': 'application/json' },
      body: JSON.stringify({
        ...data,
      }),
    })
      .then(handleResponse)
      .catch(handleError);
  } else {
    return deleteAction(baseUrl + id);
  }
}

export function deleteAction(url) {
  return fetch(url, {
    method: 'DELETE',
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function postAction(baseUrl, record) {
  return fetch(baseUrl, {
    method: 'POST',
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
    body: JSON.stringify({ ...record }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function putAction(baseUrl, record) {
  return fetch(baseUrl, {
    method: 'PUT',
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
    body: JSON.stringify({ ...record }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function upload(baseUrl, record) {
  var data = new FormData();
  for (var key in record) {
    if (Array.isArray(record[key])) {
      for (var item in record[key]) {
        data.append(key, record[key][item]);
      }
    } else {
      data.append(key, record[key]);
    }
  }
  return fetch(baseUrl, {
    method: 'POST',
    headers: { ...apiOptions().headers },
    body: data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAll(baseUrl) {
  let url = `${baseUrl}?_page=0`;
  return fetch(url, apiOptions()).then(handleResponse).catch(handleError);
}

export function get(baseUrl) {
  let url = `${baseUrl}`;
  return fetch(url, apiOptions()).then(handleResponse).catch(handleError);
}
export function download(url) {
  let filenameExp = /filename="?([^;^"]+)"?;/im;
  return fetch(url, apiOptions())
    .then(async (res) => ({
      filename:
        res.headers.get('content-disposition')?.match(filenameExp)[1] ??
        'unknown',
      blob: await res.blob(),
    }))
    .then(({ filename, blob }) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); //due to CORS can't get filename from content-disposition
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    })
    .catch(handleError);
}

export function getBase64(url) {
  let filenameExp = /filename="?([^;^"]+)"?;/im;
  return fetch(url, apiOptions())
    .then(async (res) => ({
      filename:
        res.headers.get('content-disposition')?.match(filenameExp)[1] ??
        'unknown',
      blob: await res.blob(),
    }))
    .then(({ filename, blob }) => {
      return window.URL.createObjectURL(blob);
    })
    .catch(handleError);
}

export function postArray(url, array) {
  return fetchArray(url, array, 'POST');
}
export function deleteArray(url, array) {
  return fetchArray(url, array, 'DELETE');
}

function fetchArray(url, array, method) {
  return fetch(url, {
    method: method,
    headers: { ...apiOptions().headers, 'content-type': 'application/json' },
    body: JSON.stringify([...array]),
  })
    .then(handleResponse)
    .catch(handleError);
}

export const apiUrl =
  window.globalConfig?.apiUrl || process.env.REACT_APP_API_URL;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { signoutRedirectCallback } from '../../services/userService';
import { useNavigate } from 'react-router-dom';
import { userSignedOut } from '../../actions/authActions';

function SignoutOidc({ userSignedOut }) {
  const navigate = useNavigate();
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback();
      userSignedOut().then(() => {
        navigate('/');
      });
    }
    signoutAsync();
  }, [navigate, userSignedOut]);

  return <div>Redirecting...</div>;
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  userSignedOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignoutOidc);

import * as usersApi from '../api/usersApi';

export function loadUsers({ filters, _scopes, _sort, _order, _page, _limit }) {
  return usersApi.getUsers(filters, _scopes, _sort, _order, _page, _limit);
}

export function getUsersInRole(
  roleId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  return usersApi.getUsersInRole(
    roleId,
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getAllUsers() {
  return usersApi.getAllUsers();
}

export function getAssignableUsers() {
  return usersApi.getAssignableUsers();
}

export function getUserById(id) {
  return usersApi.getUser(id);
}

export function getUserRoles(id) {
  return usersApi.getUserRoles(id);
}

export function saveUser(user) {
  return usersApi.saveUser(user);
}

export function deleteUser(id) {
  return usersApi.deleteUser(id);
}

import { Form } from 'react-bootstrap';
export function Roles({ roles, userRoles, onChange }) {
  return (
    <div className="role-item form-group row g-0 permissions">
      <label className="col-4 align-items-baseline">
        <i className="fa-light fa-user me-2 blue"></i>
        Roles:
      </label>
      <div className="col-8 flexi-box">
        {roles?.map((x) => (
          <div key={x.id} className="flexi-col">
            <div>
              <Form.Check
                type="checkbox"
                label={x.name}
                id={x.id}
                defaultChecked={userRoles?.find((r) => x.id === r)}
                onChange={(e) => onChange(x.id, e.target.checked)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import RoleList from './components/RoleList';
import RoleDetails from './components/RoleDetails';

export default function Roles({ status }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [reload, setReload] = useState();
  const [show, setShow] = useState(false);
  const [roleId, setRoleId] = useState(
    location.hash && location.hash.length > 1
      ? location.hash.substring(1, location.hash.length)
      : null
  );

  const handleClose = () => {
    setReload(new Date());
    navigate(-1);
  };

  React.useEffect(() => {
    if (location.hash !== roleId) {
      setRoleId(location.hash);
      if (location.hash && location.hash.length > 1) {
        setRoleId(location.hash.substring(1, location.hash.length));
      } else {
        setRoleId(null);
      }
    }
  }, [location.hash, roleId]);

  React.useEffect(() => {
    if (roleId && roleId.length > 1) {
      setShow(true);
    } else {
      console.log('no role id');
      setShow(false);
    }
  }, [roleId]);

  return (
    <>
      <div className="page-content roles">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-md-12 col-xl-3">
              <h1>
                <i className="fa-light fa-hammer me-2"></i>
                Roles
              </h1>
            </div>
          </div>

          <RoleList status={status} location={location} reload={reload} />

          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-dialog-centered modal-xl modal-fullscreen-xl-down"
          >
            <RoleDetails id={roleId} key={roleId} onDone={handleClose} />
          </Modal>
        </div>
      </div>
    </>
  );
}

import * as jobAttachmentApi from '../api/jobAttachmentApi';

export function loadAttachmentsForJob(id) {
  return jobAttachmentApi.getAllAttachmentsForJob(id);
}

export function getAttachmentById(id) {
  return jobAttachmentApi.getAttachmentById(id);
}

export function saveAttachment(attachment) {
  return jobAttachmentApi.saveAttachment(attachment);
}

export function uploadAttachment(attachment) {
  return jobAttachmentApi.uploadAttachment(attachment);
}

export function deleteAttachment(id) {
  return jobAttachmentApi.deleteAttachment(id);
}

export function downloadAttachment(id) {
  return jobAttachmentApi.downloadAttachment(id);
}
export function getAttachmentBase64(id) {
  return jobAttachmentApi.getAttachmentBase64(id);
}

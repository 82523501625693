import React, { useState, useRef } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { locationSearch } from '../../../actions/customerActions';

function LocationSearch({ id, defaultValue, onFocus, onBlur, onChange }) {
  const typeaheadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const handleSearch = (query) => {
    setIsLoading(true);
    if (id) {
      locationSearch(id, query)
        .then((items) => {
          setOptions(items);
          setIsLoading(false);
        })
        .catch(() => {
          setOptions([]);
          setIsLoading(false);
        });
    } else {
      setOptions([]);
      setIsLoading(false);
    }
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      ref={typeaheadRef}
      filterBy={filterBy}
      id="search-typeahead"
      cssClass="form-control"
      isLoading={isLoading}
      minLength={3}
      onSearch={handleSearch}
      options={options}
      placeholder="Search"
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      selected={defaultValue}
      allowNew={true}
      inputProps={{ required: true }}
    />
  );
}
export default LocationSearch;

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from './store';
import { Provider as ReduxProvider } from 'react-redux';
import Jobs from './components/jobs/Jobs';
import AddJob from './components/jobs/AddJob';
import Overview from './components/jobs/Overview';
import Layout from './components/Layout';
import NotFound from './components/NotFound';
import jobStatuses from './actions/jobStatuses';
import SigninOidc from './components/auth/signin-oidc';
import SignoutOidc from './components/auth/signout-oidc';
import SilentRenewOidc from './components/auth/silent-renew-oidc';
import AccessDenied from './components/AccessDenied';
import ApiFailure from './components/ApiFailure';

import MustAuth from './components/auth/MustAuth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Customers from './components/customers/Customers';
import Users from './components/users/Users';
import AddUser from './components/users/AddUser';
import Quotes from './components/quotes/Quotes';
import AddCustomer from './components/customers/AddCustomer';
import Roles from './components/roles/Roles';
import AddRole from './components/roles/AddRole';

function App() {
  return (
    <>
      <ToastContainer autoClose="3000" hideProgressBar />
      <ReduxProvider store={store}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/access-denied" element={<AccessDenied />} />
            <Route exact path="/api-failure" element={<ApiFailure />} />
            <Route exact path="/signout-oidc" element={<SignoutOidc />} />
            <Route exact path="/signin-oidc" element={<SigninOidc />} />
            <Route
              exact
              path="/silent-renew-oidc"
              element={<SilentRenewOidc />}
            />
            <Route element={<MustAuth />}>
              <Route element={<Layout />}>
                <Route path="/" element={<Overview />} />

                <Route path="/jobs/list/all" element={<Jobs key={0} />} />
                <Route path="/jobs/list/overdue" element={<Jobs key={0} />} />
                <Route path="/jobs/list/complete" element={<Jobs key={0} />} />
                <Route
                  path="/jobs/list/unassigned"
                  element={
                    <Jobs
                      status={jobStatuses.UNASSIGNED}
                      key={jobStatuses.UNASSIGNED}
                    />
                  }
                />
                <Route
                  path="/jobs/list/need-second-visit"
                  element={
                    <Jobs
                      status={jobStatuses.NEEDSSECONDVISIT}
                      key={jobStatuses.NEEDSSECONDVISIT}
                    />
                  }
                />
                <Route
                  path="/jobs/list/need-reschedule"
                  element={
                    <Jobs
                      status={jobStatuses.NEEDSRESCHEDULING}
                      key={jobStatuses.NEEDSRESCHEDULING}
                    />
                  }
                />
                <Route
                  path="/jobs/list/ready"
                  element={
                    <Jobs status={jobStatuses.READY} key={jobStatuses.READY} />
                  }
                />
                <Route
                  path="/jobs/list/in-progress"
                  element={
                    <Jobs
                      status={jobStatuses.INPROGRESS}
                      key={jobStatuses.INPROGRESS}
                    />
                  }
                />
                <Route
                  path="/jobs/list/need-pricing"
                  element={
                    <Jobs
                      status={jobStatuses.NEEDSPRICING}
                      key={jobStatuses.NEEDSPRICING}
                    />
                  }
                />
                <Route
                  path="/jobs/list/need-invoicing"
                  element={
                    <Jobs
                      status={jobStatuses.NEEDSINVOICING}
                      key={jobStatuses.NEEDSINVOICING}
                    />
                  }
                />
                <Route
                  path="/jobs/list/invoiced"
                  element={
                    <Jobs
                      status={jobStatuses.INVOICED}
                      key={jobStatuses.INVOICED}
                    />
                  }
                />

                <Route path="/jobs/add" element={<AddJob />}></Route>

                <Route path="/customers" element={<Customers />}></Route>
                <Route path="/customer/add" element={<AddCustomer />}></Route>

                <Route path="/users" element={<Users />}></Route>
                <Route path="/users/add" element={<AddUser />}></Route>
                <Route path="/roles" element={<Roles />}></Route>
                <Route path="/roles/add" element={<AddRole />}></Route>

                <Route path="/quotes" element={<Quotes />}></Route>
                {/* <Route path="/quotes/add" element={<AddQuote />}></Route> */}
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ReduxProvider>
    </>
  );
}

export default App;

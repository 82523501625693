import React from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import { cellClick } from '../../util/table';
import { DebounceInput } from 'react-debounce-input';

function SimpleTextFilter(props) {
  return (
    <DebounceInput
      type="text"
      className="form-control"
      value={props.Value}
      minLength={1}
      debounceTimeout={500}
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
}

function HeaderColumn(props) {
  let getTHSortClassNames = (col) => {
    if (props.sortedField !== undefined) {
      if (col.Key === props.sortedField.key) {
        return (
          (props.sortedField.direction === 'asc'
            ? 'sorted_asc '
            : 'sorted_desc ') + (col.className || '')
        );
      }
      return 'sortable ' + (col.className || '');
    }
    return col.className || '';
  };

  let setSortedField = () => {
    if (props.setSortedField) {
      props.setSortedField({
        key: props.col.Key,
        direction:
          props.sortedField !== undefined &&
          props.sortedField.key === props.col.Key &&
          props.sortedField.direction === 'asc'
            ? 'desc'
            : 'asc',
      });
    }
  };
  return (
    <th
      className={getTHSortClassNames(props.col)}
      onClick={setSortedField}
      style={{ width: props.col.width || 'auto' }}
    >
      {props.col.Label}
    </th>
  );
}

function FilterColumn(props) {
  let getFilter = (col) =>
    col.Filter !== undefined ? (
      col.Filter.Render !== undefined ? (
        col.Filter.Render(col, props.filterChange)
      ) : (
        <SimpleTextFilter
          value={col.Filter.Value}
          onChange={(el) => props.filterChange(col.Key, el.target.value)}
        />
      )
    ) : (
      ''
    );
  return <th>{getFilter(props.col)}</th>;
}
function RowColumn(props) {
  let getTRSortClassNames = (col) => {
    if (props.sortedField !== undefined && col.Key === props.sortedField.key) {
      return (
        (props.sortedField.direction !== '' ? 'sorted ' : ' ') +
        (col.className || '')
      );
    }
    return 'sortable ' + (col.className || '');
  };
  let renderColInner = (col, row) =>
    col.Render !== undefined ? col.Render(row) : row[col.Key];

  return (
    <td className={getTRSortClassNames(props.col)} onClick={cellClick}>
      {renderColInner(props.col, props.row)}
    </td>
  );
}

function Table({
  columns,
  rows,
  totalRowCount,
  filteredRowCount,
  tableSettings,
  setTableSettings,
  className,
  isLoading,
  noDataComponent,
}) {
  const setSortedField = (e) =>
    setTableSettings({ ...tableSettings, sortedField: e });
  const setFilters = (e) =>
    setTableSettings({ ...tableSettings, filters: e, pageNumber: 0 });
  const setPageNumber = (e) =>
    setTableSettings({ ...tableSettings, pageNumber: e });
  const setPageSize = (e) => {
    setTableSettings({ ...tableSettings, pageSize: e, pageNumber: 0 });
  };

  const showNoDataComponent =
    !isLoading && noDataComponent !== undefined && totalRowCount === 0;

  return showNoDataComponent ? (
    noDataComponent
  ) : (
    <>
      <div className={`loadingframe ${isLoading ? 'loading' : ''}`}>
        <div className="loadingbar"></div>
      </div>
      <div className="table-wrapper">
        <table className={`table dataTable width-100 ${className || ''}`}>
          <thead>
            <tr>
              {columns.map((col) => {
                return col.Sort === false ? (
                  <HeaderColumn key={col.Key} col={col} />
                ) : (
                  <HeaderColumn
                    key={col.Key}
                    col={col}
                    sortedField={tableSettings.sortedField}
                    setSortedField={setSortedField}
                  />
                );
              })}
            </tr>

            {columns.some((x) => x.Filter !== undefined) ? (
              <tr>
                {columns.map((col) => {
                  return (
                    <FilterColumn
                      key={col.Key}
                      col={col}
                      filters={tableSettings.filters}
                      setFilters={setFilters}
                    />
                  );
                })}
              </tr>
            ) : (
              ''
            )}
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={row.Key}>
                  {columns.map((col) => {
                    return (
                      <RowColumn
                        key={col.Key}
                        col={col}
                        row={row}
                        sortedField={tableSettings.sortedField}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {rows && rows.length === 0 && !isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <i className="fa-thin fa-person-digging fa-3x me-3"></i> nothing to
            show
          </div>
        ) : (
          ''
        )}
        <Pagination
          totalRowCount={totalRowCount}
          filteredRowCount={filteredRowCount}
          pageNumber={tableSettings.pageNumber}
          setPageNumber={setPageNumber}
          pageSize={tableSettings.pageSize}
          setPageSize={setPageSize}
        />
      </div>
    </>
  );
}
Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Label: PropTypes.string.isRequired,
      SortDirection: PropTypes.number,
    })
  ).isRequired,

  rows: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
    })
  ).isRequired,

  tableSettings: PropTypes.shape({
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }),
  setTableSettings: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number.isRequired,
  filteredRowCount: PropTypes.number.isRequired,
};

/*
getUrlFilters

Takes the f_ filters from the url and returns them as an object you can add to the filter
e.g. http://localhost:3000/requests/all?f_requestStatus=pending%20approval&companyId=
would result in {requestStatus:'pending approval'}

Use it like this in the default state:
  const [tableSettings, setTableSettings] = useState({
    filters: getUrlFilters(props.location.search),
    sortedField: {
      key: 'groupReference',
      direction: 'asc',
    },
    pageNumber: 0,
    pageSize: 10,
  });

Or if you have to, you can use it in a hook, this results in multiple loads though:
  useEffect(() => {
    setTableSettings({
      ...tableSettings,
      filters: getUrlFilters(props.location.search),
    });
  }, [props.location.search]);

*/

const getTableSettingsDefaults = (defaultSort, search, direction, pageSize) => {
  return {
    sortedField: {
      key: defaultSort,
      direction: direction || 'asc',
    },
    pageNumber: 0,
    pageSize: pageSize || 10,
  };
};

export { Table, SimpleTextFilter, getTableSettingsDefaults };

import AddCustomerStep1 from './components/AddCustomerStep1';

export default function AddCustomer() {
  return (
    <>
      <div className="page-content addCustomer">
        <div className="container-fluid">
          <AddCustomerStep1></AddCustomerStep1>
        </div>
      </div>
    </>
  );
}

import {
  Date_AsString,
  CommaNumber_WithDecimal,
  CommaNumber_NoDecimal,
} from '../../../util/formatter';

import Gravatar from 'react-gravatar';

export default function TimeEntries({ job, jobTimeEntries }) {
  return jobTimeEntries ? (
    <>
      <h2 className="mt-3 pb-2 d-flex justify-content-between align-items-center">
        <span>Time Entries</span>
      </h2>
      <div className="group mb-3 time-entries">
        <div className="container-fluid g-0">
          {jobTimeEntries.length > 0 ? (
            <>
              <div className="row g-0 mb-2">
                <div className="col-3 job-item white mb-0">
                  <label>
                    <i className="fa-light fa-user blue me-2"></i>
                    Name
                  </label>
                </div>
                <div className="col-3 job-item white mb-0">
                  <label>
                    <i className="fa-light fa-calendar blue me-2"></i>
                    Started
                  </label>
                </div>
                <div className="col-3 job-item white mb-0">
                  <label>
                    <i className="fa-light fa-calendar blue me-2"></i>
                    Finished
                  </label>
                </div>
                <div className="col-3 job-item white mb-0">
                  <label>
                    <i className="fa-light fa-stopwatch blue me-2"></i>
                    Duration
                  </label>
                </div>
              </div>

              {jobTimeEntries.map((x) => (
                <div className="row g-0 mb-2" key={x.id}>
                  <div className="col-3 job-item white mb-0">{x.startedBy}</div>
                  <div className="col-3 job-item white mb-0">
                    {Date_AsString(x.startedDate)}
                  </div>
                  <div className="col-3 job-item white mb-0">
                    {Date_AsString(x.completedDate)}
                  </div>
                  <div className="col-3 job-item white mb-0">
                    {x.totalDurationMinutes}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p>none</p>
          )}
        </div>
      </div>
    </>
  ) : (
    ''
  );
}

export default function UserForm({ user, register, errors, customers }) {
  return (
    <>
      <div className="form-group row g-0">
        <label className="col-4">
          <i className="fa-light fa-user me-2 blue"></i>Fullname
        </label>
        <div className="col-8">
          <input
            type="text"
            autoFocus
            required
            autoComplete="off"
            className="form-control"
            placeholder="fullName"
            defaultValue={user?.fullName || ''}
            {...register('fullName', {})}
          />
          {errors.fullName && (
            <span className="field-validation-error">Required</span>
          )}
        </div>
      </div>

      <div className="form-group row g-0">
        <label className="col-4">
          <i className="fa-light fa-at me-2 blue"></i>Email address
        </label>
        <div className="col-8">
          <input
            type="text"
            required
            autoComplete="off"
            className="form-control"
            defaultValue={user?.emailAddress || ''}
            placeholder="emailAddress"
            {...register('emailAddress', {})}
          />
          {errors.emailAddress && (
            <span className="field-validation-error">Required</span>
          )}
        </div>
      </div>

      <div className="form-group row g-0">
        <label className="col-4">
          <i className="fa-light fa-mobile-android me-2 blue"></i>
          Mobile Number
        </label>
        <div className="col-8">
          <input
            type="text"
            required
            autoComplete="off"
            className="form-control"
            defaultValue={user?.mobileNumber || ''}
            placeholder="mobileNumber"
            {...register('mobileNumber', {})}
          />
          {errors.mobileNumber && (
            <span className="field-validation-error">Required</span>
          )}
        </div>
      </div>

      <div className="form-group row g-0">
        <label className="col-4">
          <i className="fa-light fa-mobile-android me-2 blue"></i>
          Customer
        </label>
        <div className="col-8">
          <select
            {...register('customerId')}
            className="form-control"
            defaultValue={user?.customerId}
          >
            <option value="">-- bellcorp --</option>
            {customers?.map((x) => (
              <option key={x.customerId} value={x.customerId}>
                {x.customerName}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import permissionTypes from '../../auth/permissionTypes';
import { Form } from 'react-bootstrap';

export default function Permissions({ permissions, onChange }) {
  const [permission, setPermission] = useState(permissions);

  const togglePermission = (p) => {
    let newVal = permission ^ p;
    setPermission(newVal);
    onChange(newVal);
  };

  const permissionElement = (label, p) => {
    return (
      <div>
        <Form.Check
          type="checkbox"
          label={label}
          id={label}
          checked={permission & p}
          onClick={() => {
            togglePermission(p);
          }}
        />
      </div>
    );
  };

  return (
    <div className="role-item form-group row g-0 permissions">
      <label className="col-4 align-items-baseline">
        <i className="fa-light fa-user me-2 blue"></i>
        Permissions:
      </label>
      <div className="col-8 flexi-box">
        <div className="flexi-col">
          {permissionElement('Work on job', permissionTypes.WORKONJOB)}
        </div>
        <div className="flexi-col">
          {permissionElement('Schedule jobs', permissionTypes.JOBSCHEDULING)}
        </div>
        <div className="flexi-col">
          {permissionElement('Price job', permissionTypes.PRICING)}
        </div>
        <div className="flexi-col">
          {permissionElement('Invoicing', permissionTypes.INVOICING)}
        </div>
        <div className="flexi-col">
          {permissionElement('Quotes', permissionTypes.QUOTES)}
        </div>
        <div className="flexi-col">
          {permissionElement(
            'Manage customers',
            permissionTypes.COMPANYMANAGEMENT
          )}
        </div>
        <div className="flexi-col">
          {permissionElement('Manage users', permissionTypes.USERMANAGEMENT)}
        </div>
      </div>
    </div>
  );
}

import React, { useMemo, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

import { uploadAttachment } from '../../../actions/jobAttachmentActions';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Loading from '../../common/Loading';

export default function UploadAttachment({ id, done }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [file, setFile] = useState();
  const onDrop = (droppedFiles) => setFile(droppedFiles[0]);

  const onSubmit = (data) => {
    if (file) {
      setIsSaving(true);
      let postData = { ...data, file };
      if (id) {
        postData = { ...postData, jobId: id };
      }
      uploadAttachment(postData)
        .then((r) => {
          done(r);
          setIsSaving(false);
        })
        .catch((e) => {
          toast.error('Failed to add attachment');
          console.error(e);
          setIsSaving(false);
        });
    } else {
      toast.error('You must choose a file');
      setIsSaving(false);
    }
  };
  const attachmentTypes = [
    { id: 4, name: 'Work order' },
    { id: 2, name: 'Method statement' },
    { id: 1, name: 'Materials sheet' },
    { id: 3, name: 'Photo' },
  ];

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const classeNames = useMemo(
    () =>
      `dropzone ${isDragActive ? 'active' : ''} ${
        isDragAccept ? 'accept positive-colour1' : ''
      } ${isDragReject ? 'reject' : ''}`,
    [isDragActive, isDragReject, isDragAccept]
  );
  return !isSaving ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-light fa-paperclip me-2"></i>Add attachment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid g-0">
            <div className="form-group row g-0">
              <label className="col-4">
                <i className="fa-light fa-building me-2 blue"></i>
                Attachment type
              </label>
              <div className="col-8">
                <select
                  {...register('attachmentTypeId')}
                  autoFocus
                  className="form-control"
                  required
                >
                  {attachmentTypes.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
                </select>
                {errors.attachmentTypeId && (
                  <span className="field-validation-error">Required</span>
                )}
              </div>
            </div>
            <div className="form-group row g-0">
              <label className="col-4">
                <i className="fa-light fa-pen-line blue me-2"></i>
                Description
              </label>
              <div className="col-8">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  placeholder="Description"
                  {...register('description', {})}
                />
                {errors.description && (
                  <span className="field-validation-error">Required</span>
                )}
              </div>
            </div>
          </div>

          <div {...getRootProps({ className: classeNames })}>
            <input {...getInputProps()} />
            <p>
              <i className="fa-light fa-paperclip-vertical"></i> Drop
              attachments here, or click to select files
            </p>
          </div>
          {acceptedFiles && acceptedFiles.length > 0 ? (
            <aside className="drop-files">
              <h3>Attachments</h3>
              <ul>{files}</ul>
            </aside>
          ) : (
            ''
          )}
          <div className="row">
            <div className="col">
              <input type="submit" className="btn btn-primary float-end mt-3" />
            </div>
          </div>
        </form>
      </Modal.Body>
    </>
  ) : (
    <Loading></Loading>
  );
}

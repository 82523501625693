import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Gravatar from 'react-gravatar';
import { toast } from 'react-toastify';
import {
  getUserById,
  getUserRoles,
  saveUser,
  deleteUser,
} from '../../../actions/userActions';
import Loading from '../../common/Loading';
import { getCustomers } from '../../../actions/customerActions';
import UserForm from './UserForm';
import { Roles } from './Roles';
import {
  getAllRoles,
  addMember,
  deleteMember,
} from '../../../actions/roleActions';

export default function UserDetails({ id, onDone }) {
  const [user, setUser] = useState();
  const [roles, setRoles] = useState();
  const [userRoles, setUserRoles] = useState();
  const savedRoles = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let updatedUser = {
      ...user,
      ...{
        fullName: data.fullName,
        emailAddress: data.emailAddress,
        mobileNumber: data.mobileNumber,
        customerId: data.customerId !== '' ? data.customerId : null,
      },
    };
    saveUser(updatedUser).then(() => {
      const addRequests = userRoles
        .filter((roleId) => savedRoles.current.indexOf(roleId) === -1)
        .map((roleId) => addMember(roleId, id));
      const removeRequests = savedRoles.current
        .filter((roleId) => userRoles.indexOf(roleId) === -1)
        .map((roleId) => deleteMember(roleId, id));
      Promise.all([...addRequests, ...removeRequests]).then(() => {
        toast.success('Saved');
        onDone();
      });
    });
  };

  useEffect(() => {
    if (id) {
      // Get the user
      getUserById(id).then((j) => {
        getUserRoles(id).then((r) => {
          setUserRoles(r.data.map((x) => x.id));
          savedRoles.current = r.data.map((x) => x.id);
          setUser(j);
        });
      });
    }
  }, [id, setUser]);

  const [customers, setCustomers] = useState();
  useEffect(() => {
    if (!customers) {
      getCustomers().then((d) => {
        setCustomers(d);
      });
    }
  }, [customers]);

  useEffect(() => {
    if (!roles) {
      getAllRoles().then((d) => {
        setRoles(d.data);
      });
    }
  }, [roles]);

  const toggleRoles = (id, checked) => {
    setUserRoles(
      checked ? [...userRoles, id] : userRoles.filter((x) => x !== id)
    );
  };

  const handleDelete = () => {
    deleteUser(id)
      .then((d) => {
        toast.success('Successfully deleted user');
        onDone();
      })
      .catch((e) => {
        toast.error('Failed to delete user');
      });
  };

  return (
    <>
      {user ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <Gravatar
                email={user.emailAddress}
                size={34}
                rating="pg"
                default="retro"
                className="CustomAvatar-image"
              />{' '}
              {user.fullName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-fluid role-details g-0">
                <div className="row">
                  <div className="col-12">
                    <div className="group">
                      <h2>User Details</h2>
                      <UserForm
                        user={user}
                        register={register}
                        errors={errors}
                        customers={customers}
                      ></UserForm>
                      <Roles
                        roles={roles}
                        userRoles={userRoles}
                        onChange={toggleRoles}
                      ></Roles>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <input
                    type="submit"
                    value="Save"
                    className="btn btn-primary float-end"
                  />
                  <input
                    type="button"
                    value="Delete"
                    className="btn btn-danger float-end me-2"
                    onClick={handleDelete}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
        </>
      ) : (
        <Loading></Loading>
      )}
    </>
  );
}

import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import LeftNavDropdown from './LeftNavDropdown';

export default function LeftNavUsers({ currentUser, onClick }) {
  return (
    <>
      <Nav activeKey="/" className="flex-column">
        <div className="branding">
          <img src={'/img/logo.svg'} alt="Bellcorp" />
        </div>

        <LeftNavDropdown title="Users" currentUser={currentUser} />

        <NavLink to="/users" onClick={onClick}>
          All users
        </NavLink>
        <NavLink to="/users/add" className="add-btn" onClick={onClick}>
          Add user
        </NavLink>

        <h3 className="border-top">
          <i className="fa-light fa-user"></i>Roles
        </h3>
        <NavLink to="/roles" onClick={onClick}>
          All roles
        </NavLink>
        <NavLink to="/roles/add" className="add-btn" onClick={onClick}>
          Add role
        </NavLink>
      </Nav>
    </>
  );
}

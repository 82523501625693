import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import { loadJobs } from '../../actions/jobActions';
import dateRanges from '../../actions/dateRanges';
import jobStatuses from '../../actions/jobStatuses';
import Loading from '../common/Loading';
import Gravatar from 'react-gravatar';

export default function Overview() {
  const navigate = useNavigate();

  const [today, setToday] = useState();
  const [tomorrow, setTomorrow] = useState();

  const processData = (d) => {
    let result = {
      unassigned: 0,
      ready: 0,
      inprogress: 0,
      complete: 0,
      people: [],
    };
    d.forEach((item) => {
      if (item.jobStatusId === jobStatuses.READY) {
        result.ready++;
      } else if (item.jobStatusId === jobStatuses.INPROGRESS) {
        result.inprogress++;
      } else if (item.jobStatusId >= jobStatuses.COMPLETE) {
        result.complete++;
      }

      // people
      if (!item.assignedTo && item.jobStatusId === jobStatuses.UNASSIGNED) {
        result.unassigned++;
      } else {
        let person = result.people.find((x) => x.id === item.assignedToId);
        if (person) {
          person.jobs++;
        } else {
          result.people.push({
            id: item.assignedToId,
            jobs: 1,
            name: item.assignedTo,
            email: item.assignedToEmail,
          });
        }
      }
    });
    return result;
  };

  React.useEffect(() => {
    loadJobs({
      status: null,
      filters: null,
      _scopes: { dateRange: dateRanges.TODAY },
      _sort: '',
      _order: '',
      _page: 1,
      _limit: 100,
    }).then((d) => {
      setToday(processData(d.data));
    });
  }, []);

  React.useEffect(() => {
    loadJobs({
      status: null,
      filters: null,
      _scopes: { dateRange: dateRanges.TOMORROW },
      _sort: '',
      _order: '',
      _page: 1,
      _limit: 100,
    }).then((d) => {
      setTomorrow(processData(d.data));
    });
  }, []);
  return today && tomorrow ? (
    <>
      <div className="page-content overview">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1>
                <i className="fa-light fa-browser me-2"></i>Jobs Overview
              </h1>
            </div>
          </div>

          <div className="row ">
            <div className="col d-flex align-items-center mb-2 h-38">
              <h2 className="m-0">
                <i className="fa-light fa-calendar-days"></i>Today
              </h2>
            </div>
          </div>
          <div className="row ">
            <div className="col-12 col-lg-4">
              <div className="card orange mb-3">
                <div
                  className="card-body"
                  onClick={() => navigate('/jobs/list/ready?s_dateRange=today')}
                >
                  <h3 className="white">
                    Ready <i className="fa-light fa-hammer"></i>
                  </h3>
                  <span className="Jobs-num">{today.ready}</span>
                  <span>Jobs</span>
                  <ProgressBar
                    now={
                      today.ready > 0
                        ? (100 /
                            (today.ready + today.inprogress + today.complete)) *
                          today.ready
                        : 0
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="card blue mb-3">
                <div
                  className="card-body"
                  onClick={() =>
                    navigate('/jobs/list/in-progress?s_dateRange=today')
                  }
                >
                  <h3 className="white">
                    In Progress <i className="fa-light fa-hourglass"></i>
                  </h3>
                  <span className="Jobs-num">{today.inprogress}</span>
                  <span>Jobs</span>
                  <ProgressBar
                    now={
                      today.inprogress > 0
                        ? (100 /
                            (today.ready + today.inprogress + today.complete)) *
                          today.inprogress
                        : 0
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="card green mb-3">
                <div
                  className="card-body"
                  onClick={() =>
                    navigate(
                      '/jobs/list/all?s_dateRange=today&s_minStatus=complete'
                    )
                  }
                >
                  <h3 className="white">
                    Complete <i className="fa-light fa-circle-check"></i>
                  </h3>
                  <span className="Jobs-num">{today.complete}</span>
                  <span>Jobs</span>
                  <ProgressBar
                    now={
                      today.complete > 0
                        ? (100 /
                            (today.ready + today.inprogress + today.complete)) *
                          today.complete
                        : 0
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col team d-flex flex-wrap">
              {today.unassigned > 0 && (
                <div
                  className="team-member"
                  onClick={() =>
                    navigate('/jobs/list/unassigned?s_dateRange=today')
                  }
                >
                  <span className="user me-2 unassigned">?</span>
                  <span className="name">Unassigned</span>
                  <span className="num">{today.unassigned}</span>
                </div>
              )}
              {today.people.map((x) => (
                <div
                  key={x.id}
                  className="team-member"
                  onClick={() =>
                    navigate(
                      '/jobs/list/all?s_dateRange=today&f_assignedTo=' +
                        encodeURIComponent(x.name)
                    )
                  }
                >
                  <span className="user me-2">
                    <Gravatar
                      email={x.email}
                      size={20}
                      rating="pg"
                      default="retro"
                      className="CustomAvatar-image"
                    />
                  </span>
                  <span className="name">{x.name}</span>
                  <span className="num">{x.jobs}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="row ">
            <div className="col d-flex align-items-center mb-2 h-38">
              <h2 className="m-0">
                <i className="fa-light fa-calendar-days"></i>Tomorrow
              </h2>
            </div>
          </div>
          <div className="row ">
            <div className="col-12 col-lg-6">
              <div className="card red mb-3">
                <div
                  className="card-body"
                  onClick={() =>
                    navigate('/jobs/list/unassigned?s_dateRange=tomorrow')
                  }
                >
                  <h3 className="white">
                    Unassigned<i className="fa-light fa-user-large-slash"></i>
                  </h3>
                  <span className="Jobs-num">{tomorrow.unassigned}</span>
                  <span>Jobs</span>
                  <ProgressBar
                    now={
                      tomorrow.unassigned > 0
                        ? (100 / (tomorrow.unassigned + tomorrow.ready)) *
                          tomorrow.unassigned
                        : 0
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card orange mb-3">
                <div
                  className="card-body"
                  onClick={() =>
                    navigate('/jobs/list/ready?s_dateRange=tomorrow')
                  }
                >
                  <h3 className="white">
                    Ready<i className="fa-light fa-hammer"></i>
                  </h3>
                  <span className="Jobs-num">{tomorrow.ready}</span>
                  <span>Jobs</span>
                  <ProgressBar
                    now={
                      tomorrow.ready > 0
                        ? (100 / (tomorrow.unassigned + tomorrow.ready)) *
                          tomorrow.ready
                        : 0
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col team d-flex flex-wrap">
              {tomorrow.unassigned > 0 && (
                <div
                  className="team-member"
                  onClick={() =>
                    navigate('/jobs/list/unassigned?s_dateRange=tomorrow')
                  }
                >
                  <span className="user me-2 unassigned">?</span>
                  <span className="name">Unassigned</span>
                  <span className="num">{tomorrow.unassigned}</span>
                </div>
              )}

              {tomorrow.people.map((x) => (
                <div
                  key={x.id}
                  className="team-member"
                  onClick={() =>
                    navigate(
                      '/jobs/list/all?s_dateRange=tomorrow&f_assignedTo=' +
                        encodeURIComponent(x.name)
                    )
                  }
                >
                  <span className="user me-2">
                    <Gravatar
                      email={x.email}
                      size={20}
                      rating="pg"
                      default="retro"
                      className="CustomAvatar-image"
                    />
                  </span>
                  <span className="name">{x.name}</span>
                  <span className="num">{x.jobs}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loading></Loading>
  );
}

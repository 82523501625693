export function userHasPermission(currentUser, permission) {
  return currentUser && (currentUser.permissions & permission) != 0;
}

export function userHasAnyPermission(currentUser, permissions) {
  return (
    currentUser && permissions?.some((x) => (currentUser.permissions & x) != 0)
  );
}

export function userHasAllPermissions(currentUser, permissions) {
  return (
    currentUser && permissions?.every((x) => (currentUser.permissions & x) != 0)
  );
}

export function navPermissionCheck(_currentUser, _allowedPermissions) {
  let result = false;
  if (_currentUser && !_currentUser.isLoading) {
    let permission = true;
    if (_allowedPermissions !== undefined) {
      permission = userHasAnyPermission(_currentUser, _allowedPermissions);
    }
    result = permission;
  }
  return result;
}

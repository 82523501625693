import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { saveRole } from '../../../actions/roleActions';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import Permissions from './Permissions';

export default function AddRoleStep1() {
  const navigate = useNavigate();
  const [permission, setPermission] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();
  const onSubmit = (data) => {
    saveRole({
      name: data.name,
      permissions: permission,
    }).then(() => {
      toast.success('Role added');
      navigate('/roles');
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col">
          <h1 className="d-flex align-items-center justify-content-between">
            <span>
              <i className="fa-light fa-circle-plus me-2"></i>Add role
            </span>
            <input type="submit" className="btn btn-primary float-end" />
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-8">
          <div className="card">
            <div className="card-body">
              <h2>Role Details</h2>
              <div className="form-group row g-0">
                <label className="col-4">
                  <i className="fa-light fa-hashtag me-2 blue"></i>Role name
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    required
                    autoComplete="off"
                    className="form-control"
                    placeholder="role name"
                    {...register('name', {})}
                  />
                  {errors.name && (
                    <span className="field-validation-error">Required</span>
                  )}
                </div>
              </div>
              <Permissions
                onChange={(newVal) => {
                  setPermission(newVal);
                }}
              ></Permissions>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <input type="submit" className="btn btn-primary float-end" />
        </div>
      </div>
    </form>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { saveComment } from '../../../actions/jobCommentActions';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loading from '../../common/Loading';

export default function AddComment({ id, done }) {
  const [isSaving, setIsSaving] = useState(false);

  const focusInput = useRef();
  useEffect(() => {
    const timer = setTimeout(() => {
      focusInput?.current && focusInput.current.focus();
    }, 5);
    return timer;
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { ref, ...rest } = register('comment');

  const onSubmit = (data) => {
    setIsSaving(true);
    saveComment({
      jobId: id,
      comment: data.comment,
      jobAttachmentIds: [],
    }).then(() => {
      toast.success('Comment added');
      done();
      setIsSaving(false);
    });
  };

  return !isSaving ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-light fa-message-plus me-2"></i>Add comment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid g-0">
          <div className="form-group row g-0">
            <div className="col-12 mb-3">
              <textarea
                {...register('comment', {})}
                className="form-control"
                autoFocus={true}
                ref={(e) => {
                  ref(e);
                  focusInput.current = e; // you can still assign to ref
                }}
                required
              />
              {errors.comment && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-end">
              <button
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary"
                disabled={isSaving}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  ) : (
    <Loading></Loading>
  );
}

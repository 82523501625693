import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { useLocalStorage } from './useLocalStorage';
import useLoader from './useLoader';
import { toast } from 'react-toastify';

const useTableSettings = (
  key,
  defaults,
  parameters,
  loadList,
  setList,
  reload,
  errorMessage,
  location
) => {
  const [isLoading, startLoader, clearLoader] = useLoader();
  const [tableSettings, setTableSettings] = useLocalStorage(key, defaults);
  const refParameters = useRef(parameters);
  const checkPageResult = useCallback(
    (pageNumber) => {
      pageNumber--;
      if (pageNumber > 0 && pageNumber !== tableSettings.pageNumber) {
        setTableSettings({
          ...tableSettings,
          pageNumber: pageNumber,
        });
      }
    },
    [tableSettings, setTableSettings]
  );

  const navigate = useNavigate();
  let filterChange = useCallback(
    (key, value) => {
      // set the search param in the url?
      const params = new URLSearchParams(location.search);
      if (value === '') {
        params.delete(`f_${[key]}`);
      } else {
        params.set(`f_${[key]}`, value);
      }
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
      setTableSettings({
        ...tableSettings,
        filters: { ...tableSettings.filters, [key]: value },
        pageNumber: 0,
      });
    },
    [tableSettings, setTableSettings, location.search]
  );

  const getScopeFilters = (search) => {
    const params = new URLSearchParams(search);
    let scopes = {};
    for (var key of params.keys()) {
      if (key.startsWith('s_')) {
        scopes = {
          ...scopes,
          [key.substring(2)]: params.get(key),
        };
      }
    }
    return scopes;
  };

  const getUrlFilters = (search) => {
    const params = new URLSearchParams(search);
    let filters = {};
    for (var key of params.keys()) {
      if (key.startsWith('f_')) {
        filters = {
          ...filters,
          [key.substring(2)]: params.get(key),
        };
      }
    }
    return filters;
  };

  useEffect(() => {
    startLoader();
    loadList({
      ...{
        filters: getUrlFilters(location.search),
        _scopes: getScopeFilters(location.search),
        _sort: tableSettings.sortedField.key,
        _order: tableSettings.sortedField.direction,
        _page: tableSettings.pageNumber,
        _limit: tableSettings.pageSize,
      },
      ...refParameters.current,
    })
      .then((d) => {
        setList(d);
        checkPageResult(d.page);
      })
      .catch(() => {
        toast.error(errorMessage);
      })
      .finally(clearLoader);
  }, [
    startLoader,
    clearLoader,
    reload,
    tableSettings.scopes,
    tableSettings.sortedField,
    tableSettings.pageNumber,
    tableSettings.pageSize,
    checkPageResult,
    loadList,
    setList,
    errorMessage,
    location.search,
  ]);

  return [
    tableSettings,
    setTableSettings,
    filterChange,
    isLoading,
    refParameters,
  ];
};
export default useTableSettings;

import React, { useState } from 'react';
import { loadRoles } from '../../../actions/roleActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import useTableSettings from '../../../hooks/useTableSettings';

function RoleList({ dateRange, status, location, reload }) {
  const [visibleColumns] = useState(['name', 'memberCount']);

  const [roles, setRoles] = useState();
  const params = new URLSearchParams(location.search);
  const [tableSettings, setTableSettings, filterChange, isLoading] =
    useTableSettings(
      'rolelist-settings',
      getTableSettingsDefaults('name', location.search, 'asc'),
      null,
      loadRoles,
      setRoles,
      reload,
      'Failed to load roles',
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'name',
      Label: 'Name',
      width: '25%',
      Render: (role) => <Link to={'#' + role.id}>{role.name}</Link>,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key="search"
            Value={params.get(`f_search`) || ''}
            onChange={(value) => filterChange('search', value)}
          />
        ),
      },
    },

    {
      Key: 'memberCount',
      Label: '# Members',
      width: '75%',
      Render: (role) => CommaNumber_NoDecimal(role.memberCount),
    },
  ];

  // filter
  let columns = visibleColumns.map((x) =>
    columnsConfig.find((y) => y.Key === x)
  );

  // return a table
  return roles ? (
    <div>
      <Table
        columns={columns}
        rows={roles.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={roles.totalRowCount}
        filteredRowCount={roles.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
      />
    </div>
  ) : (
    <Loading></Loading>
  );
}

export default RoleList;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { loadJobs, getJobById } from '../../../actions/jobActions';
import { Modal } from 'react-bootstrap';
import Loading from '../../common/Loading';
import { SortableComponent } from './SortableComponent';
import { Date_AsString } from '../../../util/formatter';

function ScheduleJobOrder({
  assignedToId,
  scheduledStartDate,
  done,
  stateJobs,
  jobId,
}) {
  const [jobs, setJobs] = useState();
  const [reload, setReload] = useState();

  const handleSubmit = () => {
    let result = jobs.map((x, i) => {
      return {
        jobId: x.id,
        order: i,
      };
    });

    done(result);
  };

  useEffect(() => {
    loadJobs({
      status: null,
      filters: null,
      _scopes: {
        date: new Date(scheduledStartDate).toISOString(),
        assignedTo: assignedToId,
      },
      _sort: 'assignedOrder',
      _order: '',
      _page: 1,
      _limit: 100,
    }).then((d) => {
      let jobData = d.data;
      if (!jobData.find((a) => a.id === jobId)) {
        // get this new job
        getJobById(jobId).then((theJob) => {
          setJobs([...jobData, theJob]);
        });
      } else {
        setJobs(jobData);
      }
    });
  }, [reload]);

  const handleSortChange = (d) => {
    setJobs(d.items);
  };

  return jobs ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-light fa-message-plus me-2"></i>Set job order for{' '}
          {Date_AsString(scheduledStartDate)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid g-0">
          <SortableComponent items={jobs} onChange={handleSortChange} />
        </div>
        <div className="row">
          <div className="col d-flex justify-content-end">
            <button onClick={handleSubmit} className="btn btn-primary">
              Save job order
            </button>
          </div>
        </div>
      </Modal.Body>
    </>
  ) : (
    <Loading></Loading>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    stateJobs: state.jobs,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleJobOrder);

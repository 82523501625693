import * as customerApi from '../api/customerApi';

export function loadCustomers({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return customerApi.getCustomers(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}

export function getCustomers() {
  return customerApi.getAllCustomers();
}

export function getCustomerById(id) {
  return customerApi.getCustomerById(id);
}

export function locationSearch(id, query) {
  return customerApi.locationSearch(id, query);
}

export function saveCustomer(customer) {
  return customerApi.saveCustomer(customer);
}

export function updateCustomer(customer) {
  return customerApi.updateCustomer(customer);
}

export function deleteCustomer(id) {
  return customerApi.deleteCustomer(id);
}

export const cellClick = (e) => {
  const delayMs = 0; //500;
  let table = e.target.closest('table');
  let tr = e.target.closest('tr');

  let addClasses = () => {
    table.classList.add('_row_click');
    table.classList.add('_row_click_added');
  };

  let stopNav = (item) => {
    if (item.classList.contains('no-navigate')) {
      return true;
    }
  };

  let test = (item) => {
    if (
      item.className !== undefined &&
      item.className.indexOf('checkmark') > -1
    ) {
      // checkbox
      return true;
    } else if (
      item.classList.contains('a') ||
      item.nodeName === 'A' ||
      item.nodeName === 'BUTTON' ||
      (item.className !== undefined && item.className.indexOf('checkmark') > -1)
    ) {
      // link or button
      tr.classList.add('clicked-navigating');
      item.classList.add('clicked-target');
      return true;
    }
  };

  if (stopNav(e.target)) {
    return true;
  }
  if (test(e.target)) {
    addClasses();
    return true;
  }

  let parents = getParentsUntil(e.target.parentsUntil, 'td');
  for (var i = 0; i < parents.length; i++) {
    let item = parents[i];
    if (stopNav(item)) {
      return true;
    }
    if (test(item)) {
      addClasses();
      return true;
    }
  }

  // find an a in this TD?
  let children = e.target.querySelector("button,input[type='submit']");
  if (children !== undefined && children !== null) {
    e.preventDefault();
    e.cancelBubble = true;
    tr.classList.add('clicked-navigating');
    children.classList.add('clicked-target');
    setTimeout(() => {
      addClasses();
      children.click();
    }, delayMs);
    return false;
  }

  // any stopnav in this TD?
  let nonavchildren = e.target.querySelector('.no-navigate');
  if (nonavchildren !== undefined && nonavchildren !== null) {
    e.preventDefault();
    e.cancelBubble = true;
    return false;
  }

  addClasses();

  // find the first A or button in the row
  children = tr.querySelector(".a,a,button,input[type='submit']");
  if (children !== undefined && children !== null) {
    e.preventDefault();
    e.cancelBubble = true;
    tr.classList.add('clicked-navigating');
    if (
      children.hasAttribute('nodelay') &&
      children.attributes['nodelay'].value === 'true'
    ) {
      children.click();
    } else {
      setTimeout(() => {
        children.click();
      }, delayMs);
    }
    children.classList.add('clicked-target');
    return false;
  }
  return true;
};

/**
 * Get all of an element's parent elements up the DOM tree until a matching parent is found
 * @param  {Node}   elem     The element
 * @param  {String} parent   The selector for the parent to stop at
 * @param  {String} selector The selector to filter against [optionals]
 * @return {Array}           The parent elements
 */
var getParentsUntil = function (elem, parent, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Setup parents array
  var parents = [];

  // Get matching parent elements
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (parent) {
      if (elem.matches(parent)) break;
    }

    if (selector) {
      if (elem.matches(selector)) {
        parents.push(elem);
      }
      break;
    }

    parents.push(elem);
  }

  return parents;
};

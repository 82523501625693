import AddJobStep1 from './components/AddJobStep1';

export default function AddJob() {
  return (
    <>
      <div className="page-content addJob">
        <div className="container-fluid">
          <AddJobStep1></AddJobStep1>
        </div>
      </div>
    </>
  );
}

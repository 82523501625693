import React, { useState, useEffect } from 'react';
import {
  downloadAttachment,
  getAttachmentBase64,
} from '../../../actions/jobAttachmentActions';
export default function AttachmentImage({ id, alt }) {
  const [img, setImg] = useState();

  const fetchImage = async () => {
    getAttachmentBase64(id).then((d) => {
      setImg(d);
    });
  };

  useEffect(() => {
    fetchImage();
  }, []);

  let handleDownloadImage = () => {
    downloadAttachment(id);
  };

  return (
    <>
      <span
        className="a"
        style={{ float: 'right' }}
        onClick={() => handleDownloadImage()}
      >
        <i className="fa-solid fa-download"></i> Download image
      </span>
      <img src={img} alt={alt} className="img-fluid" />
    </>
  );
}

import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import LeftNavDropdown from './LeftNavDropdown';

export default function LeftNavQuotes({ currentUser, onClick }) {
  return (
    <>
      <Nav activeKey="/" className="flex-column">
        <div className="branding">
          <img src={'/img/logo.svg'} alt="Bellcorp" />
        </div>

        <LeftNavDropdown title="Quotes" currentUser={currentUser} />

        <NavLink to="/quotes" onClick={onClick}>
          All quotes
        </NavLink>
        <NavLink to="/quotes/add" className="add-btn" onClick={onClick}>
          Add quote
        </NavLink>
      </Nav>
    </>
  );
}

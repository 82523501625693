import {
  apiUrl,
  getAll,
  getList,
  getSingleRecord,
  handleResponse,
  handleError,
  apiOptions,
  saveRecord,
  deleteRecord,
  putAction,
} from './apiUtils';
const baseUrl = apiUrl + '/customers/';

export function getCustomers(filters, _scopes, _sort, _order, _page, _limit) {
  let url = `${baseUrl}list`;
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getAllCustomers() {
  return getAll(`${baseUrl}list`);
}

export function getCustomerById(id) {
  return getSingleRecord(baseUrl, id);
}
export function locationSearch(id, query) {
  return fetch(`${baseUrl}${id}/locationsearch/${query}`, apiOptions())
    .then(handleResponse)
    .catch(handleError);
}

export function saveCustomer(customer) {
  return saveRecord(baseUrl, customer);
}

export function updateCustomer(customer) {
  return putAction(baseUrl, customer);
}

export function deleteCustomer(id) {
  return deleteRecord(baseUrl, id);
}

import React, { useEffect } from 'react';
import { signinRedirectCallback } from '../../services/userService';
import { useNavigate } from 'react-router-dom';

function SigninOidc() {
  const navigate = useNavigate();
  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback()
        .then((user) => {
          navigate(user.state || '/');
        })
        .catch((e) => {
          console.error(e);
          //history.push('/');
        });
    }
    signinAsync();
  }, [navigate]);

  return <div>Redirecting...</div>;
}

export default SigninOidc;

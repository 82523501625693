import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRoleById } from '../../../actions/roleActions';
import { saveRole } from '../../../api/rolesApi';
import Permissions from './Permissions';
import Loading from '../../common/Loading';

export default function RoleDetails({ id, onDone }) {
  const [role, setRole] = useState();
  const [permission, setPermission] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    saveRole({
      id: id,
      name: data.name,
      permissions: permission,
    }).then(() => {
      toast.success('Saved');
      onDone();
    });
  };

  useEffect(() => {
    if (id) {
      // Get the role
      getRoleById(id).then((j) => {
        setPermission(j.permissions);
        setRole(j);
      });
    }
  }, [id, setRole]);

  return (
    <>
      {role ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Role Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-fluid role-details g-0">
                <div className="row">
                  <div className="col-12">
                    <div className="group">
                      <h2 className="no-border p-0">{role.name}</h2>
                      <div className="form-group row g-0">
                        <label className="col-4">
                          <i className="fa-light fa-hashtag me-2 blue"></i>Role
                          name
                        </label>
                        <div className="col-8">
                          <input
                            type="text"
                            required
                            autoComplete="off"
                            className="form-control"
                            placeholder="role name"
                            defaultValue={role.name}
                            {...register('name', {})}
                          />
                          {errors.name && (
                            <span className="field-validation-error">
                              Required
                            </span>
                          )}
                        </div>
                      </div>
                      <Permissions
                        onChange={(newVal) => {
                          setPermission(newVal);
                        }}
                        permissions={permission}
                      ></Permissions>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <input
                    type="submit"
                    value="Save"
                    className="btn btn-primary float-end"
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
        </>
      ) : (
        <Loading></Loading>
      )}
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Loading from '../../common/Loading';

export default function ConfirmDialog({
  icon,
  title,
  message,
  trueText,
  falseText,
  onTrue,
  onFalse,
}) {
  const [isSaving, setIsSaving] = useState(false);

  const handleClick = (then) => {
    setIsSaving(true);
    then();
  };

  return !isSaving ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className={`${icon || 'fa-solid fa-circle-question'} me-2`}></i>{' '}
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid g-0">
          {message && (
            <div className="row">
              <p>{message}</p>
            </div>
          )}
          <div className="row">
            <div className="col d-flex justify-content-end">
              <button
                onClick={() => handleClick(onTrue)}
                className="btn btn-primary"
                disabled={isSaving}
              >
                {trueText}
              </button>
              <button
                onClick={() => handleClick(onFalse)}
                className="btn btn-default"
                disabled={isSaving}
              >
                {falseText}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  ) : (
    <Loading></Loading>
  );
}

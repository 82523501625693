const permissionTypes = {
  WORKONJOB: 1,
  JOBSCHEDULING: 2,
  PRICING: 4,
  INVOICING: 8,
  QUOTES: 16,
  COMPANYMANAGEMENT: 32,
  USERMANAGEMENT: 64,
};
export default permissionTypes;

import React, { useState, useEffect } from 'react';
import { deleteJobById, saveJob } from '../../../actions/jobActions';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

// https://reactdatepicker.com/
// https://github.com/Hacker0x01/react-datepicker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LocationSearch from './LocationSearch';
import Loading from '../../common/Loading';
import ConfirmDialog from './ConfirmDialog';

export default function EditJob({ job, currentUser, done, onDelete }) {
  const [isSaving, setIsSaving] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [location, setLocation] = useState([job?.location]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsSaving(true);
    let location = data.location;
    if (Array.isArray(data.location)) {
      if (data.location.length > 0) {
        location = data.location[0];
      } else {
        location = '';
      }
    }
    saveJob({
      id: job.id,
      location: location,
      description: data.description,
      priority: data.priority,
      timescaleDays: data.timescaleDays,
      completionDueDate: data.completionDueDate,
      receivedFromCustomerDate: data.receivedFromCustomerDate,
    }).then((values) => {
      toast.success('Saved');
      done();
      setIsSaving(false);
    });
  };

  let handleDelete = () => {
    setShowDelete(true);
  };

  let handleOnTrue = () => {
    deleteJobById(job.id).then(() => {
      setShowDelete(false);
      onDelete();
    });
  };

  return job ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-light fa-circle-check me-2"></i>Job details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid g-0">
          <div className="form-group row g-0">
            <label className="col-4">
              <i className="fa-light fa-hashtag me-2 blue"></i>Work order
            </label>
            <div className="col-8">
              <input
                type="text"
                required
                autoComplete="off"
                className="form-control"
                placeholder="Work order"
                defaultValue={job.workOrder}
                {...register('workOrder', {})}
              />
              {errors.workOrder && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>
          <div className="form-group row g-0">
            <label className="col-4">
              <i className="fa-light fa-location-dot me-2 blue"></i>
              Location
            </label>
            <div className="col-8">
              <Controller
                control={control}
                rules={{ required: true }}
                name="location"
                defaultValue={location}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <LocationSearch
                    id={job.customerId}
                    onBlur={(e) => {
                      onChange([e.target.value]);
                      setLocation([e.target.value]);
                    }}
                    onChange={(v) => {
                      setLocation(v);
                      onChange(v);
                    }}
                    defaultValue={location}
                  />
                )}
              />
              {errors.receivedFromCustomerDate && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>

          <div className="form-group row g-0">
            <label className="col-4 align-items-start">
              <i className="fa-light fa-pen-line me-2 blue"></i>
              Description
            </label>
            <div className="col-8">
              <textarea
                {...register('description', {})}
                className="form-control"
                required
                defaultValue={job.description}
              />
              {errors.description && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>

          <div className="form-group row g-0">
            <label className="col-4">
              <i className="fa-light fa-arrow-down-big-small me-2 blue"></i>
              Priority
            </label>
            <div className="col-8">
              <select
                {...register('priority')}
                className="form-control"
                defaultValue={job.priority}
                required
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
              {errors.priority && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>

          <div className="form-group row g-0">
            <label className="col-4">
              <i className="fa-light fa-calendar-days me-2 blue"></i>
              Received
            </label>
            <div className="col-8">
              <Controller
                control={control}
                required
                name="receivedFromCustomerDate"
                defaultValue={new Date(job.receivedFromCustomerDate)}
                render={({ field }) => (
                  <DatePicker
                    required
                    dateFormat="dd/MMM/yyyy HH:mm"
                    className="form-control"
                    placeholderText="Select date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    showTimeSelect
                  />
                )}
              />
              {errors.receivedFromCustomerDate && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>

          <div className="form-group row g-0">
            <label className="col-4">
              <i className="fa-light fa-rotate-right me-2 blue"></i>
              Complete within
            </label>
            <div className="col-8">
              <select
                {...register('timescaleDays')}
                className="form-control"
                defaultValue={job.timescaleDays}
                required
              >
                <option value="1">1 day</option>
                <option value="2">2 days</option>
                <option value="5">5 days</option>
                <option value="10">10 days</option>
              </select>
              {errors.timescaleDays && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>

          <div className="form-group row g-0 mb-3">
            <label className="col-4">
              <i className="fa-light fa-flag-checkered me-2 blue"></i>
              Completion due
            </label>
            <div className="col-8">
              <Controller
                control={control}
                name="completionDueDate"
                defaultValue={new Date(job.completionDueDate)}
                render={({ field }) => (
                  <DatePicker
                    dateFormat="dd/MMM/yyyy HH:mm"
                    className="form-control"
                    placeholderText="Select date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    showTimeSelect
                  />
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col d-flex justify-content-between">
              <button
                onClick={handleDelete}
                className="btn btn-danger"
                disabled={isSaving}
              >
                <i className="fa-solid fa-trash-can"></i> Delete
              </button>
              <button
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary"
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        dialogClassName="modal-dialog-centered modal-md "
      >
        <ConfirmDialog
          icon=""
          title="Delete job?"
          message="Are you sure you want to delete this job?"
          trueText={'Yes, delete'}
          falseText={'No'}
          onTrue={handleOnTrue}
          onFalse={() => setShowDelete(false)}
        />
      </Modal>
    </>
  ) : (
    <Loading></Loading>
  );
}

import actionTypes from '../actions/actionTypes';
function reducer(state = [], action) {
  switch (action.type) {
    case actionTypes.JOB_CREATED:
    case actionTypes.JOB_UPDATED:
    case actionTypes.JOB_DELETED:
      return state ? [...state, action.job] : state;
    default:
      return state;
  }
}
export default reducer;

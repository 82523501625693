import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import LeftNavDropdown from './LeftNavDropdown';

export default function LeftNavCustomers({ currentUser, onClick }) {
  return (
    <>
      <Nav activeKey="/" className="flex-column">
        <div className="branding">
          <img src={'/img/logo.svg'} alt="Bellcorp" />
        </div>

        <LeftNavDropdown title="Customers" currentUser={currentUser} />

        <NavLink to="/customers" onClick={onClick}>
          All customers
        </NavLink>
        <NavLink to="/customer/add" className="add-btn" onClick={onClick}>
          Add customer
        </NavLink>
      </Nav>
    </>
  );
}

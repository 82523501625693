import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getJobStats } from '../../actions/jobActions';
import jobStatuses from '../../actions/jobStatuses';
import dateRanges from '../../actions/dateRanges';
import { userHasAnyPermission, userHasPermission } from '../auth/authUtils';
import permissionTypes from '../auth/permissionTypes';
import LeftNavDropdown from './LeftNavDropdown';

export default function LeftNav({ currentUser, jobs, onClick }) {
  const [allStats, setAllStats] = useState();
  const [todayStats, setTodayStats] = useState();
  const [beforeNowStats, setBeforeNowStats] = useState();

  useEffect(() => {
    if (!allStats) {
      getJobStats(null, null).then((s) => {
        setAllStats(s);
      });
    }
  }, [allStats]);

  useEffect(() => {
    if (!todayStats) {
      getJobStats(null, dateRanges.TODAY).then((s) => {
        setTodayStats(s);
      });
    }
  }, [todayStats]);

  useEffect(() => {
    if (!beforeNowStats) {
      getJobStats(null, dateRanges.BEFORENOW).then((s) => {
        setBeforeNowStats(s);
      });
    }
  }, [beforeNowStats]);

  useEffect(() => {
    if (jobs) {
      setAllStats();
      setTodayStats();
      setBeforeNowStats();
    }
  }, [jobs]);

  const getStat = (jobStatus, dateRange, colour) => {
    let s;
    if (dateRange === dateRanges.ALL) {
      s = allStats;
    } else if (dateRange === dateRanges.TODAY) {
      s = todayStats;
    } else if (dateRange === dateRanges.BEFORENOW) {
      s = beforeNowStats;
    }
    if (!s) {
      return '';
    }
    let stat = s.data.find((x) => x.jobStatusId === jobStatus);
    if (stat) {
      return <span className={colour}>{stat.count}</span>;
    }
    return '';
  };

  const getStatMinStatus = (minJobStatus, dateRange, colour) => {
    let s;
    if (dateRange === dateRanges.ALL) {
      s = allStats;
    } else if (dateRange === dateRanges.TODAY) {
      s = todayStats;
    } else if (dateRange === dateRanges.BEFORENOW) {
      s = beforeNowStats;
    }
    if (!s) {
      return '';
    }
    let stat = s.data.find((x) => x.jobStatusId >= minJobStatus);
    if (stat) {
      return <span className={colour}>{stat.count}</span>;
    }
    return '';
  };
  const getStatMaxStatus = (maxJobStatus, dateRange, colour) => {
    let s;
    if (dateRange === dateRanges.ALL) {
      s = allStats;
    } else if (dateRange === dateRanges.TODAY) {
      s = todayStats;
    } else if (dateRange === dateRanges.BEFORENOW) {
      s = beforeNowStats;
    }
    if (!s) {
      return '';
    }
    let stat = s.data.find((x) => x.jobStatusId <= maxJobStatus);
    if (stat) {
      return <span className={colour}>{stat.count}</span>;
    }
    return '';
  };

  return currentUser && !currentUser.isLoading ? (
    <>
      <Nav activeKey="/" className="flex-column">
        <div className="branding">
          <img src={'/img/logo.svg'} alt="Bellcorp" />
        </div>

        <LeftNavDropdown title="Jobs" currentUser={currentUser} />

        <NavLink to="/" onClick={onClick}>
          Overview
        </NavLink>
        <NavLink to="/jobs/list/all" onClick={onClick}>
          All jobs
        </NavLink>
        {userHasPermission(currentUser, permissionTypes.JOBSCHEDULING) && (
          <NavLink to="/jobs/add" className="add-btn" onClick={onClick}>
            Add job
          </NavLink>
        )}

        {userHasPermission(currentUser, permissionTypes.JOBSCHEDULING) && (
          <>
            <h3 className="border-top">
              <i className="fa-light fa-calendar-pen"></i>Scheduling
            </h3>
            <NavLink to="/jobs/list/unassigned" onClick={onClick}>
              Unassigned jobs{' '}
              {getStat(jobStatuses.UNASSIGNED, dateRanges.ALL, 'red')}
            </NavLink>
            <NavLink to="/jobs/list/need-second-visit" onClick={onClick}>
              Needs second visit{' '}
              {getStat(jobStatuses.NEEDSSECONDVISIT, dateRanges.ALL, 'orange')}
            </NavLink>
            <NavLink to="/jobs/list/need-reschedule" onClick={onClick}>
              Needs rescheduling{' '}
              {getStat(jobStatuses.NEEDSRESCHEDULING, dateRanges.ALL, 'orange')}
            </NavLink>
            <NavLink
              to="/jobs/list/overdue?s_dateRange=beforenow&s_maxStatus=needsRescheduling"
              onClick={onClick}
            >
              Overdue{' '}
              {getStatMaxStatus(
                jobStatuses.NEEDSRESCHEDULING,
                dateRanges.BEFORENOW,
                'orange'
              )}
            </NavLink>
          </>
        )}

        <h3 className="border-top">
          <i className="fa-light fa-hammer"></i>Today's jobs
        </h3>
        <NavLink to="/jobs/list/ready?s_dateRange=today" onClick={onClick}>
          Ready {getStat(jobStatuses.READY, dateRanges.TODAY, 'red')}
        </NavLink>
        <NavLink
          to="/jobs/list/in-progress?s_dateRange=today"
          onClick={onClick}
        >
          In progress{' '}
          {getStat(jobStatuses.INPROGRESS, dateRanges.TODAY, 'orange')}
        </NavLink>
        <NavLink
          to="/jobs/list/complete?s_dateRange=today&s_minStatus=complete"
          onClick={onClick}
        >
          Complete{' '}
          {getStatMinStatus(jobStatuses.COMPLETE, dateRanges.TODAY, 'green')}
        </NavLink>

        {userHasAnyPermission(currentUser, [
          permissionTypes.INVOICING,
          permissionTypes.PRICING,
        ]) && (
          <>
            <h3 className="border-top">
              <i className="fa-light fa-coins"></i>Finance
            </h3>
            {userHasPermission(currentUser, permissionTypes.PRICING) && (
              <NavLink to="/jobs/list/need-pricing" onClick={onClick}>
                Need pricing{' '}
                {getStat(jobStatuses.NEEDSPRICING, dateRanges.ALL, 'blue')}
              </NavLink>
            )}
            {userHasPermission(currentUser, permissionTypes.INVOICING) && (
              <>
                <NavLink to="/jobs/list/need-invoicing" onClick={onClick}>
                  Need invoicing{' '}
                  {getStat(jobStatuses.NEEDSINVOICING, dateRanges.ALL, 'blue')}
                </NavLink>
                <NavLink to="/jobs/list/invoiced">Invoiced</NavLink>
              </>
            )}
          </>
        )}
      </Nav>
    </>
  ) : (
    ''
  );
}

import React, { Component } from 'react';
import { render } from 'react-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = SortableElement(({ job }) => (
  <li className="sortable-item" tabIndex={0}>
    <div class="job-item white">
      <i class="fa-light fa-location-dot me-2 blue"></i>
      {job.location}
    </div>
    <div class="job-item white">
      <i class="fa-light fa-building me-2 blue"></i>
      {job.customerName}
    </div>
    <div class="job-item white">
      <i class="fa-light fa-hashtag me-2 blue"></i>
      {job.workOrder}
    </div>
    <div class="job-item white">
      <i class="fa-light fa-pen-line me-2 blue"></i>
      {job.description}
    </div>
  </li>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <ol className="sortable-container">
      {items.map((value, index) => (
        <SortableItem key={value.id} index={index} job={value} />
      ))}
    </ol>
  );
});

export class SortableComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      items: props.items,
    };
  }

  handleChange() {
    // Update component state whenever the data source changes
    this.setState({
      items: this.props.items,
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));

    if (this.props.onChange) {
      this.props.onChange(this.state);
    }
  };
  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}

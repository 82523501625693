import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { userHasPermission } from '../auth/authUtils';
import permissionTypes from '../auth/permissionTypes';

export default function LeftNavDropdown({ currentUser, title }) {
  return (
    <Dropdown className="dropnav">
      <Dropdown.Toggle id="dropdown-basic">{title}</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item>
          <NavLink to="/">
            <i className="fa-light fa-hammer me-2 blue"></i>Jobs
          </NavLink>
        </Dropdown.Item>
        {userHasPermission(currentUser, permissionTypes.COMPANYMANAGEMENT) && (
          <Dropdown.Item>
            <NavLink to="/customers">
              <i className="fa-light fa-building me-2 blue"></i>Customers
            </NavLink>
          </Dropdown.Item>
        )}
        {userHasPermission(currentUser, permissionTypes.QUOTES) && (
          <Dropdown.Item>
            <NavLink to="/quotes">
              <i className="fa-light fa-file-invoice me-2 blue"></i>Quotes
            </NavLink>
          </Dropdown.Item>
        )}
        {userHasPermission(currentUser, permissionTypes.USERMANAGEMENT) && (
          <Dropdown.Item>
            <NavLink to="/users">
              <i className="fa-light fa-user me-2 blue"></i>Users
            </NavLink>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

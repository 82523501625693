import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Gravatar from 'react-gravatar';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getJobById, loadJobTimeEntries } from '../../../actions/jobActions';
import {
  loadAttachmentsForJob,
  downloadAttachment,
} from '../../../actions/jobAttachmentActions';
import { loadCommentsForJob } from '../../../actions/jobCommentActions';
import {
  DateAndTime_AsString,
  Date_AsString,
  statusText,
} from '../../../util/formatter';
import { userHasAnyPermission } from '../../auth/authUtils';
import permissionTypes from '../../auth/permissionTypes';
import Loading from '../../common/Loading';
import Comments from './Comments';
import EditFinance from './EditFinance';
import Finance from './Finance';
import ScheduleJob from './ScheduleJob';
import StartJob from './StartJob';
import StopJob from './StopJob';
import UploadAttachment from './UploadAttachment';
import EditJob from './EditJob';
import TimeEntries from './TimeEntries';

function JobDetails({ id, currentUser, jobs, onClose }) {
  const [job, setJob] = useState();
  const [reloadJob, setReloadJob] = useState();
  const [reloadComments, setReloadComments] = useState();
  const [jobComments, setJobComments] = useState();
  const [jobAttachments, setJobAttachments] = useState();
  const [jobTimeEntries, setJobTimeEntries] = useState();
  const [reloadAttachments, setReloadAttachments] = useState();
  const [reloadTimeEntries, setReloadTimeEntries] = useState();
  const [showUpload, setShowUpload] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [showStart, setShowStart] = useState(false);
  const [showStop, setShowStop] = useState(false);
  const [showEditFinance, setShowEditFinance] = useState(false);
  const [showEditJob, setShowEditJob] = useState(false);
  const mountedRef = useRef(true);
  useEffect(() => {
    if (id) {
      // Get the job
      getJobById(id).then((j) => {
        if (!mountedRef.current) return null;
        setJob(j);
      });
    }
  }, [id, setJob, reloadJob]);

  useEffect(() => {
    if (id) {
      // Get the job
      loadCommentsForJob(id).then((d) => {
        if (!mountedRef.current) return null;
        setJobComments(d);
      });
    }
  }, [id, setJobComments, reloadComments]);

  useEffect(() => {
    if (id) {
      // Get the job
      loadAttachmentsForJob(id).then((d) => {
        if (!mountedRef.current) return null;
        setJobAttachments(d);
      });
    }
  }, [id, setJobAttachments, reloadAttachments]);

  useEffect(() => {
    if (jobs && jobs.find((x) => x.id === id)) {
      setReloadJob(new Date());
      setReloadComments(new Date());
    }
  }, [jobs, id]);

  useEffect(() => {
    if (id) {
      // Get the job
      loadJobTimeEntries(id).then((d) => {
        if (!mountedRef.current) return null;
        setJobTimeEntries(d.data);
      });
    }
  }, [id, setJobTimeEntries, reloadTimeEntries]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleViewOnMap = (location) => {
    window.open(
      `https://www.google.com/maps/search/${encodeURIComponent(location)}`
    );
  };

  const handleUploadClose = () => {
    setShowUpload(false);
  };
  const handleAddAttachmentDone = () => {
    setShowUpload(false);
    setReloadAttachments(new Date());
  };
  const handleDownload = (attachment) => {
    downloadAttachment(attachment.jobAttachmentId);
  };
  const handleScheduleDone = (d) => {
    setJob(d);
    setReloadComments(new Date());
    setShowSchedule(false);
    setReloadTimeEntries(new Date());
  };
  const handleStartDone = (d) => {
    setJob(d);
    setReloadComments(new Date());
    setShowStart(false);
    setReloadTimeEntries(new Date());
  };
  const handleStopDone = (d) => {
    setJob(d);
    setReloadComments(new Date());
    setShowStop(false);
    setReloadTimeEntries(new Date());
  };
  const handleEditFinanceDone = (d) => {
    setReloadJob(new Date());
    setShowEditFinance(false);
  };
  const handleEditJobDone = (d) => {
    setReloadJob(new Date());
    setShowEditJob(false);
  };
  const handleOnDelete = () => {
    onClose();
  };

  return (
    <>
      {job && jobComments ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-light fa-hammer me-2"></i> {job.workOrder}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid job-details g-0">
              <div className="row">
                <div className="col-12 col-lg-7">
                  <div className="group">
                    <h2 className="d-flex justify-content-between align-items-center">
                      Job Details
                      <button
                        className="btn btn-secondary"
                        onClick={() => setShowEditJob(true)}
                      >
                        <i className="fa-light fa-pencil" />
                      </button>
                    </h2>
                    <div className="job-item white">
                      <label>
                        <i className="fa-light fa-building me-2 blue"></i>
                        Customer:
                      </label>
                      {job.customerName}
                    </div>
                    <div className="job-item white">
                      <span className="d-flex align-items-center justify-content-between">
                        <label>
                          <i className="fa-light fa-location-dot me-2 blue"></i>
                          Location:
                        </label>
                        <span
                          className="view green"
                          onClick={() => handleViewOnMap(job.location)}
                        >
                          <i className="fa-light fa-map-location-dot me-2"></i>{' '}
                          View on map
                        </span>
                      </span>

                      <div className="d-flex ">
                        <span>{job.location}</span>
                      </div>
                    </div>
                    <div className="job-item white">
                      <label className="text-colour">
                        <i className="fa-light fa-pen-line me-2 blue"></i>
                        Job Description:
                      </label>
                      {job.description}
                    </div>

                    <div className="job-item">
                      <label>
                        <i className="fa-light fa-arrow-down-big-small me-2 blue"></i>
                        Priority:
                      </label>
                      {job.priority}
                    </div>
                    <div className="job-item pb-3 border-bottom">
                      <label>
                        <i className="fa-light fa-rotate-right me-2 blue"></i>
                        Timescale:
                      </label>
                      {job.timescaleDays} days
                    </div>
                    <h3 className="mt-3 d-flex justify-content-between align-items-center">
                      Attachments
                      <button
                        className="btn btn-secondary"
                        onClick={() => setShowUpload(true)}
                      >
                        <i className="fa-light fa-plus" />
                      </button>
                    </h3>

                    <Modal
                      show={showUpload}
                      onHide={handleUploadClose}
                      dialogClassName="modal-dialog-centered modal-lg modal-fullscreen-xl-down"
                    >
                      <UploadAttachment
                        id={job.id}
                        done={handleAddAttachmentDone}
                      ></UploadAttachment>
                    </Modal>
                    {jobAttachments && jobAttachments.length > 0 ? (
                      jobAttachments.map((x) => (
                        <div
                          className="attachment"
                          onClick={() => handleDownload(x)}
                        >
                          <span>
                            <i className="fa-light fa-paperclip me-2 "></i>
                            {x.description ?? x.filename}
                          </span>
                          <span className="type">{x.attachmentTypeName}</span>
                        </div>
                      ))
                    ) : (
                      <p>
                        <i className="fa-light fa-paperclip-vertical"></i> No
                        attachments
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="group mb-3">
                    <h2 className="d-flex justify-content-between align-items-end mb-3">
                      <span>
                        Status:{' '}
                        <span className="white">
                          {statusText(job.jobStatusId)}
                        </span>
                      </span>
                      <span
                        className="complete-due"
                        onClick={() => setShowEditJob(true)}
                      >
                        Completion due:{' '}
                        <span className="white">
                          {Date_AsString(job.completionDueDate, '---', true)}
                        </span>
                      </span>
                    </h2>
                    <div className="container-fluid g-0">
                      <div className="row g-0">
                        <div
                          className="col-12 status-item clickable complete mb-2"
                          onClick={() => setShowEditJob(true)}
                        >
                          <div className="i-wrapper">
                            <i></i>
                          </div>
                          <div className="w-100 py-2 px-3">
                            <div className="d-flex justify-content-between w-100">
                              <div className="title">Received</div>
                              <div className="date text-colour">
                                {Date_AsString(
                                  job.receivedFromCustomerDate,
                                  '---'
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => setShowSchedule(true)}
                          className={`col-12 status-item clickable mb-2 ${
                            job.scheduledStartDate ? 'complete' : 'not-started'
                          }`}
                        >
                          <div className="i-wrapper">
                            <i></i>
                          </div>
                          <div className="w-100 py-2 px-3">
                            <div className="d-flex justify-content-between w-100">
                              <div className="title">Scheduled</div>

                              <div className="date text-colour">
                                {Date_AsString(
                                  job.scheduledStartDate,
                                  '',
                                  true
                                )}
                              </div>
                            </div>
                            {job.assignedToEmail ? (
                              <div className="assigned">
                                <Gravatar
                                  email={job.assignedToEmail}
                                  size={20}
                                  rating="pg"
                                  default="retro"
                                  className="CustomAvatar-image"
                                />
                                <span> {job.assignedTo}</span>
                              </div>
                            ) : (
                              <div className="unassigned">
                                <span className="unassigned-icon">?</span>
                                <span>Unassigned</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          onClick={() => setShowStart(true)}
                          className={`col-12 status-item clickable mb-2 ${
                            job.startedDate ? 'complete' : 'not-started'
                          }`}
                        >
                          <div className="i-wrapper">
                            <i className="fa-light fa-check green"></i>
                          </div>
                          <div className="w-100 py-2 px-3">
                            <div className="d-flex justify-content-between w-100">
                              <div className="title">Started</div>
                              <div className="date text-colour">
                                {DateAndTime_AsString(job.startedDate, '---')}
                              </div>
                            </div>
                            {job.startedByEmail && (
                              <div className="assigned">
                                <Gravatar
                                  email={job.startedByEmail}
                                  size={20}
                                  rating="pg"
                                  default="retro"
                                  className="CustomAvatar-image"
                                />
                                <span>{job.startedBy} </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (job.startedDate) {
                              setShowStop(true);
                            } else {
                              toast.error('Job not yet started');
                            }
                          }}
                          className={`col-12 status-item clickable ${
                            job.completedDate
                              ? 'complete'
                              : job.startedDate
                              ? 'active'
                              : 'not-started'
                          }`}
                        >
                          <div className="i-wrapper">
                            {' '}
                            <i className="fa-light fa-hourglass text-colour"></i>
                          </div>
                          <div className="w-100 py-2 px-3">
                            <div className="d-flex justify-content-between w-100">
                              <div className="title">Complete</div>

                              <div className="date text-colour">
                                {DateAndTime_AsString(job.completedDate, '---')}
                              </div>
                            </div>
                            {job.completedByEmail && (
                              <div className="assigned">
                                <Gravatar
                                  email={job.completedByEmail}
                                  size={20}
                                  rating="pg"
                                  default="retro"
                                  className="CustomAvatar-image"
                                />
                                <span> {job.completedBy}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <TimeEntries job={job} jobTimeEntries={jobTimeEntries} />
                  {userHasAnyPermission(currentUser, [
                    permissionTypes.INVOICING,
                    permissionTypes.PRICING,
                  ]) && (
                    <Finance
                      job={job}
                      onEdit={() => setShowEditFinance(true)}
                    />
                  )}
                  <Comments
                    job={job}
                    jobComments={jobComments}
                    reload={() => setReloadComments(new Date())}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal
            show={showSchedule}
            onHide={() => setShowSchedule(false)}
            dialogClassName="modal-dialog-centered modal-lg modal-fullscreen-xl-down"
          >
            <ScheduleJob
              id={job.id}
              scheduledStartDate={job.scheduledStartDate}
              assignedToId={job.assignedToId}
              done={handleScheduleDone}
            />
          </Modal>
          <Modal
            show={showStart}
            onHide={() => setShowStart(false)}
            dialogClassName="modal-dialog-centered modal-lg modal-fullscreen-xl-down"
          >
            <StartJob
              id={job.id}
              startedDate={job.startedDate}
              startedByUserId={job.startedById || job.assignedToId}
              done={handleStartDone}
            />
          </Modal>
          <Modal
            show={showStop}
            onHide={() => setShowStop(false)}
            dialogClassName="modal-dialog-centered modal-lg modal-fullscreen-xl-down"
          >
            <StopJob
              id={job.id}
              stoppedDate={job.completedDate}
              stoppedByUserId={job.completedById || job.startedById}
              done={handleStopDone}
            />
          </Modal>
          <Modal
            show={showEditFinance}
            onHide={() => setShowEditFinance(false)}
            dialogClassName="modal-dialog-centered modal-lg modal-fullscreen-xl-down"
          >
            <EditFinance
              job={job}
              currentUser={currentUser}
              done={handleEditFinanceDone}
            />
          </Modal>
          <Modal
            show={showEditJob}
            onHide={() => setShowEditJob(false)}
            dialogClassName="modal-dialog-centered modal-lg modal-fullscreen-xl-down"
          >
            <EditJob
              job={job}
              currentUser={currentUser}
              done={handleEditJobDone}
              onDelete={handleOnDelete}
            />
          </Modal>
        </>
      ) : (
        <Loading delay={0}></Loading>
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    id: ownProps.id,
    jobs: state.jobs,
    onClose: ownProps.onClose,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);

import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import CustomerList from './components/CustomerList';
import CustomerDetails from './components/CustomerDetails';

export default function Customers({ status }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [reload, setReload] = useState();
  const [show, setShow] = useState(false);
  const [customerId, setCustomerId] = useState(
    location.hash && location.hash.length > 1
      ? location.hash.substring(1, location.hash.length)
      : null
  );

  const handleClose = () => {
    setReload(new Date());
    navigate(-1);
  };

  React.useEffect(() => {
    if (location.hash !== customerId) {
      setCustomerId(location.hash);
      if (location.hash && location.hash.length > 1) {
        setCustomerId(location.hash.substring(1, location.hash.length));
      } else {
        setCustomerId(null);
      }
    }
  }, [location.hash, customerId]);

  React.useEffect(() => {
    if (customerId && customerId.length > 1) {
      setShow(true);
    } else {
      console.log('no customer id');
      setShow(false);
    }
  }, [customerId]);

  const handleOnDelete = () => {
    setShow(false);
    setReload(new Date());
  };

  return (
    <>
      <div className="page-content customers">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-md-12 col-xl-3">
              <h1>
                <i className="fa-light fa-building me-2"></i>
                Customers
              </h1>
            </div>
          </div>

          <CustomerList status={status} location={location} reload={reload} />

          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-dialog-centered modal-lg modal-fullscreen-xl-down"
          >
            <CustomerDetails
              id={customerId}
              key={customerId}
              onDelete={handleOnDelete}
              onReload={() => setReload(new Date())}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}

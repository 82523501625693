import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import LeftNavJobs from './layout/LeftNavJobs';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Gravatar from 'react-gravatar';
import LeftNavCustomers from './layout/LeftNavCustomers';
import LeftNavUsers from './layout/LeftNavUsers';
import LeftNavQuotes from './layout/LeftNavQuotes';
import { signoutRedirect } from '../services/userService';

function Layout({ currentUser, jobs }) {
  const location = useLocation();
  const [sideNavOpen, setSideSideNavOpen] = useState(false);
  const handleOnClick = () => {
    setSideSideNavOpen((prevState) => !prevState);
  };
  const handleNavOnClick = () => {
    setSideSideNavOpen(false);
  };
  const handleSignout = () => {
    signoutRedirect();
  };
  return (
    <main>
      <div className={`left-nav ${sideNavOpen ? 'open' : ''}`}>
        {location.pathname.startsWith('/customer') ? (
          <LeftNavCustomers
            currentUser={currentUser}
            onClick={handleNavOnClick}
          ></LeftNavCustomers>
        ) : location.pathname.startsWith('/user') ||
          location.pathname.startsWith('/role') ? (
          <LeftNavUsers
            currentUser={currentUser}
            onClick={handleNavOnClick}
          ></LeftNavUsers>
        ) : location.pathname.startsWith('/quote') ? (
          <LeftNavQuotes
            currentUser={currentUser}
            onClick={handleNavOnClick}
          ></LeftNavQuotes>
        ) : (
          <LeftNavJobs
            currentUser={currentUser}
            jobs={jobs}
            onClick={handleNavOnClick}
          ></LeftNavJobs>
        )}
      </div>
      <div className="content">
        <div className="header">
          {/* <Form className="search">
            <Form.Group controlId="search">
              <Form.Control type="text" placeholder="search" />
            </Form.Group>
          </Form> */}
          {currentUser && currentUser.emailAddress && (
            <span
              className="a me-2"
              onClick={handleSignout}
              title={`Sign out ${currentUser.name}`}
            >
              Sign out
            </span>
          )}
          <div className="user ms-3">
            {currentUser && currentUser.emailAddress && (
              <Gravatar
                email={currentUser.emailAddress}
                size={34}
                rating="pg"
                default="retro"
                className="CustomAvatar-image"
                title={currentUser.name}
              />
            )}
          </div>
          <Button variant="primary" onClick={handleOnClick}>
            <i className="fa-light fa-bars"></i>
          </Button>
        </div>
        <Outlet />
      </div>
    </main>
  );
}
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    jobs: state.jobs,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

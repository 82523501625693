import {
  apiUrl,
  deleteAction,
  deleteRecord,
  getAll,
  getList,
  getSingleRecord,
  postAction,
  saveRecord,
} from './apiUtils';
const baseUrl = apiUrl + '/roles/';

export function getRoles(filters, _scopes, _sort, _order, _page, _limit) {
  let url = `${baseUrl}list`;
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getAllRoles() {
  let url = `${baseUrl}`;
  return getAll(url);
}

export function getRole(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveRole(role) {
  return saveRecord(baseUrl, role);
}

export function deleteRole(id) {
  return deleteRecord(baseUrl, id);
}

export function deleteMember(id, userId) {
  return deleteAction(`${baseUrl}${id}/member/${userId}`);
}
export function addMember(id, userId) {
  return postAction(`${baseUrl}${id}/member/${userId}`);
}

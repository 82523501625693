import React, { useState } from 'react';
import Gravatar from 'react-gravatar';
import { Tooltip } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { loadJobs } from '../../../actions/jobActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import {
  CommaNumber_NoDecimal,
  Date_AsString,
  FriendlyTimeFromMinutes,
} from '../../../util/formatter';
import Loading from '../../common/Loading';
import useTableSettings from '../../../hooks/useTableSettings';
import AutoSelectText from '../../common/AutoSelectText';

function JobList({ dateRange, status, location, reload }) {
  const [visibleColumns] = useState([
    'workOrder',
    'customerName',
    'scheduledStartDate',
    'completedDate',
    'assignedTo',
  ]);

  const [jobs, setJobs] = useState();
  const params = new URLSearchParams(location.search);
  const [tableSettings, setTableSettings, filterChange, isLoading] =
    useTableSettings(
      'joblist-settings',
      getTableSettingsDefaults('scheduledStartDate', location.search, 'desc'),
      {
        status: status,
      },
      loadJobs,
      setJobs,
      reload,
      'Failed to load jobs',
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'customerName',
      Label: 'Customer',
      width: '30%',

      Render: (job) => (
        <>
          <div className="mb-2">
            <Link to={location.search + '#' + job.id}>{job.customerName}</Link>
          </div>
          <div className="location mb-2">{job.location}</div>
          <div className="desc">{job.description}</div>
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key="search"
            Value={params.get(`f_search`) || ''}
            onChange={(value) => filterChange('search', value)}
          />
        ),
      },
    },

    {
      Key: 'scheduledStartDate',
      Label: 'Scheduled/Issued',
      width: '10%',
      Render: (job) => (
        <>
          <div className="d-block mb-2">
            <OverlayTrigger
              placement="right"
              delay={{ show: 150, hide: 150 }}
              overlay={<Tooltip>Scheduled</Tooltip>}
            >
              <div className="label scheduled">
                <span className="w-20 green">
                  <i className="fa-light fa-hammer"></i>
                </span>
                {Date_AsString(job.scheduledStartDate, '', true)}
              </div>
            </OverlayTrigger>
          </div>
          <div className="d-block mb-2">
            <OverlayTrigger
              placement="right"
              delay={{ show: 150, hide: 150 }}
              overlay={<Tooltip>Isssued</Tooltip>}
            >
              <div className="label issued">
                <span className="w-20 blue">
                  <i className="fa-light fa-calendar-days"></i>{' '}
                </span>
                {Date_AsString(job.receivedFromCustomerDate)}
              </div>
            </OverlayTrigger>
          </div>
          <div className="d-block mb-2">
            <OverlayTrigger
              placement="right"
              delay={{ show: 150, hide: 150 }}
              overlay={<Tooltip>Turn Around</Tooltip>}
            >
              <div className="label turnaround">
                <span className="w-20 blue">
                  <i className="fa-light fa-rotate-right"></i>
                </span>
                {job.timescaleDays} days
              </div>
            </OverlayTrigger>
          </div>
        </>
      ),
    },
    {
      Key: 'completedDate',
      Label: 'Complete',
      width: '10%',
      Render: (job) => <>{Date_AsString(job.completedDate)}</>,
    },
    {
      Key: 'workOrder',
      Label: 'Work order#',
      width: '10%',
      className: 'orderNo',
      Render: (job) => job.workOrder,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'assignedTo',
      Label: 'Assigned To',
      width: '30%',
      className: 'table-text-ellipsis',
      Render: (job) => (
        <>
          {job.assignedToEmail && (
            <div className="assigned mb-3">
              <Gravatar
                email={job.assignedToEmail}
                size={20}
                rating="pg"
                default="retro"
                className="CustomAvatar-image"
              />
              <span> {job.assignedTo}</span>
            </div>
          )}
          {job.lastComment && (
            <div className="job-comment">"{job.lastComment}"</div>
          )}
        </>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
  ];

  // filter
  let columns = visibleColumns.map((x) =>
    columnsConfig.find((y) => y.Key === x)
  );

  // return a table
  return jobs ? (
    <div>
      <Table
        columns={columns}
        rows={jobs.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={jobs.totalRowCount}
        filteredRowCount={jobs.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
      />
    </div>
  ) : (
    <Loading></Loading>
  );
}

export default JobList;

import * as jobCommentsApi from '../api/jobCommentsApi';

export function loadCommentsForJob(id) {
  return jobCommentsApi.getCommentsForJob(id);
}

export function getCommentById(id) {
  return jobCommentsApi.getCommentById(id);
}

export function saveComment(comment) {
  return jobCommentsApi.saveComment(comment);
}

export function deleteComment(id) {
  return jobCommentsApi.deleteComment(id);
}

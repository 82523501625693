export default {
  ALL: -1,
  TODAY: 1,
  TOMORROW: 2,
  YESTERDAY: 3,
  THISWEEK: 4,
  NEXTWEEK: 5,
  LASTWEEK: 6,
  BEFORENOW: 7,
};

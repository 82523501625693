import {
  Date_AsString,
  CommaNumber_WithDecimal,
  CommaNumber_NoDecimal,
} from '../../../util/formatter';

export default function Finance({ job, onEdit }) {
  return (
    <>
      <h2 className="mt-3 pb-2 d-flex justify-content-between align-items-center">
        <span>Finance</span>
      </h2>
      <div className="group finance" onClick={() => onEdit()}>
        <div className="container-fluid g-0">
          <div className="row g-0">
            <div className="col-md col-lg-12 d-flex flex-row mb-3 mb-md-0  mb-lg-3">
              <div className="col-4 d-flex flex-column job-item white">
                <label>
                  <i className="fa-light fa-block-brick blue me-2"></i>
                  Materials
                </label>
                £{CommaNumber_WithDecimal(job.costOfMaterials ?? 0)}
              </div>
              <div className="col-4 d-flex flex-column job-item white">
                <label>
                  <i className="fa-light fa-block-brick blue me-2"></i>
                  Labor (&pound;)
                </label>
                £{CommaNumber_WithDecimal(job.costOfLabor ?? 0)}
              </div>
              <div className="col-4 d-flex flex-column job-item white">
                <label>
                  <i className="fa-light fa-user-helmet-safety blue me-2"></i>
                  Labour (mins)
                </label>
                {CommaNumber_NoDecimal(job.totalDurationMinutes ?? 0)} mins
              </div>
            </div>
            <div className="col-md d-flex flex-row">
              <div className="col-4 d-flex flex-column job-item white">
                <label>
                  <i className="fa-light fa-hashtag blue me-2"></i>
                  Total cost (&pound;)
                </label>
                £{CommaNumber_WithDecimal(job.totalCost ?? 0)}
              </div>
              <div className="col-8 d-flex flex-column job-item white">
                <label>
                  <i className="fa-light fa-hashtag blue me-2"></i>
                  Invoice #
                </label>
                {job.invoiceNumber || '---'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

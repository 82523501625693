import {
  apiUrl,
  deleteRecord,
  get,
  getAll,
  getList,
  getSingleRecord,
  saveRecord,
} from './apiUtils';
const baseUrl = apiUrl + '/users/';

export function getUsers(filters, _scopes, _sort, _order, _page, _limit) {
  let url = `${baseUrl}list`;
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getUsersInRole(
  roleId,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit
) {
  let url = `${baseUrl}role/${roleId}`;
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getAllUsers() {
  let url = `${baseUrl}`;
  return getAll(url);
}

export function getAssignableUsers() {
  let url = `${baseUrl}assignable`;
  return getAll(url);
}

export function getUser(id) {
  return getSingleRecord(baseUrl, id);
}

export function getUserRoles(id) {
  return get(`${baseUrl}${id}/roles`, id);
}

export function saveUser(user) {
  return saveRecord(baseUrl, user);
}

export function deleteUser(id) {
  return deleteRecord(baseUrl, id);
}
export function getCurrentUser() {
  return get(`${baseUrl}authenticated`);
}

import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getCustomers } from '../../../actions/customerActions';
import { saveJob } from '../../../actions/jobActions';
import jobStatuses from '../../../actions/jobStatuses';
import Loading from '../../common/Loading';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import UploadAttachment from './UploadAttachment';

// https://reactdatepicker.com/
// https://github.com/Hacker0x01/react-datepicker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LocationSearch from './LocationSearch';
import {
  saveAttachment,
  uploadAttachment,
} from '../../../actions/jobAttachmentActions';
import DragAndDrop from '../../common/DragAndDrop';
import LoadingOverlay from '../../common/LoadingOverlay';

export default function AddJobStep1() {
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [jobAttachments, setJobAttachments] = useState([]);
  const [location, setLocation] = useState([]);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const onSubmit = (data) => {
    let location = data.location;
    if (Array.isArray(data.location)) {
      if (data.location.length > 0) {
        location = data.location[0];
      } else {
        location = '';
      }
    }
    setIsSaving(true);
    saveJob({
      jobStatusId: jobStatuses.UNASSIGNED,
      customerId: data.customerId,
      receivedFromCustomerDate: data.receivedFromCustomerDate
        ? new Date(data.receivedFromCustomerDate)
        : null,
      location: location,
      description: data.description,
      workOrder: data.workOrder,
      scheduledStartDate: data.scheduledStartDate
        ? new Date(data.scheduledStartDate)
        : null,
      completionDueDate: data.completionDueDate
        ? new Date(data.completionDueDate)
        : null,
      timescaleDays: data.timescaleDays,
      priority: data.priority,
    })
      .then((newJob) => {
        // associate attachments
        jobAttachments.forEach((x) => {
          saveAttachment({
            id: x.id,
            jobId: newJob.id,
            attachmentTypeId: x.attachmentTypeId,
            description: x.description,
            filename: x.filename,
          });
        });
        toast.success('Job added');
        navigate('/jobs/list/all');
      })
      .catch(() => {
        setIsSaving(false);
        toast.error('Failed to add job');
      });
  };
  const [customerId, setCustomerId] = useState();
  const [customers, setCustomers] = useState();
  useEffect(() => {
    if (!customers) {
      getCustomers().then((d) => {
        setCustomers(d);
        if (d.length > 0) {
          setCustomerId(d[0].customerId);
        }
      });
    }
  }, []);

  const handleUploadClose = () => {
    setShowUpload(false);
  };
  const handleAddAttachmentDone = (r) => {
    setShowUpload(false);
    // add the jobattachment to the attachment list.
    setJobAttachments([...jobAttachments, r]);

    // Look for any parsed data on the attachment:
    // Branch: "Earlham Green Lane Food Store, Earlham Green Lane, Norwich, Norfolk, NR4 7TE"
    // Date: "21/09/2021"
    // InvoiceNumber: null
    // IssuedBy: "SC"
    // JobDescription: "Please attend site to repair/replace down pipe & drain cover as per attached report"
    // Order: "SC14842"
    // Position: "Technical Administrator"
    // Premises: "010473 PC"
    // ReportRequired: "Y"
    // ReportedBy: "Sue Clarke"
    // SameDay: null
    // Time: "10.01"
    // To: "Bellcorp Ltd"
    // Via: "Email"
    // Within5WkDays: "selected"
    // Within20WkDays: null
    if (r.keyValuePairs) {
      // for now, assume this is coop
      setCustomerId('0b208bc8-f6be-4327-8f72-cab44229aabf');
      setValue('customerId', '0b208bc8-f6be-4327-8f72-cab44229aabf');

      if (r.keyValuePairs.Branch && !getValues('location')) {
        setValue('location', r.keyValuePairs.Branch);
        setLocation([r.keyValuePairs.Branch]);
      }
      if (r.keyValuePairs.Date) {
        setValue('receivedFromCustomerDate', new Date(r.keyValuePairs.Date));
      }
      if (r.keyValuePairs.Order && !getValues('workOrder')) {
        setValue('workOrder', r.keyValuePairs.Order);
      }
      if (r.keyValuePairs.JobDescription && !getValues('description')) {
        setValue('description', r.keyValuePairs.JobDescription);
      }
      if (r.keyValuePairs.Within5WkDays) {
        setValue('timescaleDays', 5);
      }
      if (r.keyValuePairs.Within20WkDays) {
        setValue('timescaleDays', 20);
      }

      // calc the due date?
      let timescaleDays = getValues('timescaleDays');
      let receivedFromCustomerDate = getValues('receivedFromCustomerDate');

      // change the scheduled date if it's in the past
      if (
        receivedFromCustomerDate &&
        new Date(receivedFromCustomerDate) < new Date()
      ) {
        receivedFromCustomerDate = new Date();
      }

      if (timescaleDays && receivedFromCustomerDate) {
        let d = new Date(receivedFromCustomerDate);
        let targetDate = d.setDate(d.getDate() + parseInt(timescaleDays));
        setValue('scheduledStartDate', new Date(receivedFromCustomerDate));
        setValue('completionDueDate', targetDate);
      }
    }
  };

  let handleDrop = (files) => {
    let file = files[0];
    let data = {
      attachmentTypeId: 4,
    };
    if (file) {
      setIsUploading(true);
      let postData = { ...data, file };
      uploadAttachment(postData)
        .then((r) => {
          handleAddAttachmentDone(r);
          setIsUploading(false);
        })
        .catch((e) => {
          toast.error('Failed to add attachment');
          console.error(e);
          setIsUploading(false);
        });
    } else {
      toast.error('You must choose a file');
      setIsUploading(false);
    }
  };

  return customers && !isSaving ? (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <h1 className="d-flex align-items-center justify-content-between">
              <span>
                <i className="fa-light fa-circle-plus me-2"></i>Add job
              </span>
              <input type="submit" className="btn btn-primary float-end" />
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-8">
            <div className="card">
              <DragAndDrop className="card-body" handleDrop={handleDrop}>
                <h2>Job Details</h2>
                <div className="form-group row g-0">
                  <label className="col-4">
                    <i className="fa-light fa-building me-2 blue"></i>Customer
                  </label>
                  <div className="col-8">
                    <select
                      {...register('customerId')}
                      autoFocus
                      className="form-control"
                      onChange={(e) => setCustomerId(e.target.value)}
                      required
                    >
                      <option></option>
                      {customers.map((x) => (
                        <option key={x.customerId} value={x.customerId}>
                          {x.customerName}
                        </option>
                      ))}
                    </select>
                    {errors.customerId && (
                      <span className="field-validation-error">Required</span>
                    )}
                  </div>
                </div>
                <div className="form-group row g-0">
                  <label className="col-4">
                    <i className="fa-light fa-hashtag me-2 blue"></i>Work order
                  </label>
                  <div className="col-8">
                    <input
                      type="text"
                      required
                      autoComplete="off"
                      className="form-control"
                      placeholder="Work order"
                      {...register('workOrder', {})}
                    />
                    {errors.workOrder && (
                      <span className="field-validation-error">Required</span>
                    )}
                  </div>
                </div>
                <div className="form-group row g-0">
                  <label className="col-4">
                    <i className="fa-light fa-location-dot me-2 blue"></i>
                    Location
                  </label>
                  <div className="col-8">
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="location"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <LocationSearch
                          id={customerId}
                          onBlur={(e) => {
                            onChange([e.target.value]);
                            setLocation([e.target.value]);
                          }}
                          onChange={(v) => {
                            setLocation(v);
                            onChange(v);
                          }}
                          defaultValue={location}
                        />
                      )}
                    />
                    {errors.location && (
                      <span className="field-validation-error">Required</span>
                    )}
                  </div>
                </div>

                <div className="form-group row g-0">
                  <label className="col-4 align-items-start">
                    <i className="fa-light fa-pen-line me-2 blue"></i>
                    Description
                  </label>
                  <div className="col-8">
                    <Controller
                      control={control}
                      required
                      name="description"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <textarea
                          className="form-control"
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          value={value}
                          rows={6}
                          required
                        />
                      )}
                    />
                    {errors.description && (
                      <span className="field-validation-error">Required</span>
                    )}
                  </div>
                </div>

                <div className="form-group row g-0">
                  <label className="col-4">
                    <i className="fa-light fa-arrow-down-big-small me-2 blue"></i>
                    Priority
                  </label>
                  <div className="col-8">
                    <select
                      {...register('priority')}
                      className="form-control"
                      required
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                    {errors.priority && (
                      <span className="field-validation-error">Required</span>
                    )}
                  </div>
                </div>

                <div className="form-group row g-0">
                  <label className="col-4">
                    <i className="fa-light fa-calendar-days me-2 blue"></i>
                    Received
                  </label>
                  <div className="col-8">
                    <Controller
                      control={control}
                      required
                      defaultValue={new Date()}
                      name="receivedFromCustomerDate"
                      render={({ field }) => (
                        <DatePicker
                          required
                          dateFormat="dd/MMM/yyyy"
                          className="form-control"
                          placeholderText="Select date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                        />
                      )}
                    />
                    {errors.receivedFromCustomerDate && (
                      <span className="field-validation-error">Required</span>
                    )}
                  </div>
                </div>

                <div className="form-group row g-0">
                  <label className="col-4">
                    <i className="fa-light fa-rotate-right me-2 blue"></i>
                    Complete within
                  </label>
                  <div className="col-8">
                    <select
                      {...register('timescaleDays')}
                      className="form-control"
                      required
                    >
                      <option value="1">1 day</option>
                      <option value="2">2 days</option>
                      <option value="5">5 days</option>
                      <option value="10">10 days</option>
                      <option value="20">20 days</option>
                    </select>
                    {errors.timescaleDays && (
                      <span className="field-validation-error">Required</span>
                    )}
                  </div>
                </div>

                <div className="form-group row g-0">
                  <label className="col-4">
                    <i className="fa-light fa-hammer me-2 blue"></i>Scheduled
                  </label>
                  <div className="col-8">
                    <Controller
                      control={control}
                      name="scheduledStartDate"
                      defaultValue={new Date()}
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="dd/MMM/yyyy"
                          className="form-control"
                          placeholderText="Select date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="form-group row g-0 mb-3">
                  <label className="col-4">
                    <i className="fa-light fa-flag-checkered me-2 blue"></i>
                    Completion due
                  </label>
                  <div className="col-8">
                    <Controller
                      control={control}
                      name="completionDueDate"
                      defaultValue={new Date()}
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="dd/MMM/yyyy"
                          className="form-control"
                          placeholderText="Select date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                        />
                      )}
                    />
                  </div>
                </div>
              </DragAndDrop>
            </div>
          </div>
          <div className="col-12 col-xl-4">
            <div className="card">
              <div className="card-body">
                <h2 className="d-flex align-items-center justify-content-between">
                  Attachments
                  <Button
                    className="btn btn-secondary"
                    onClick={() => setShowUpload(true)}
                  >
                    Add
                  </Button>
                </h2>
                {jobAttachments &&
                  jobAttachments.map((x) => (
                    <div key={x.id} className="attachment">
                      <span>
                        <i className="fa-light fa-paperclip me-2 "></i>
                        {x.description ?? x.filename}
                      </span>
                      <span className="type">{x.attachmentTypeName}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <input type="submit" className="btn btn-primary float-end" />
          </div>
        </div>
      </form>
      <Modal
        show={showUpload}
        onHide={handleUploadClose}
        dialogClassName="modal-dialog-centered modal-lg modal-fullscreen-xl-down"
      >
        <UploadAttachment done={handleAddAttachmentDone}></UploadAttachment>
      </Modal>
      {isUploading && (
        <LoadingOverlay message="Processing..." delay={0}></LoadingOverlay>
      )}
    </>
  ) : (
    <Loading delay={0}></Loading>
  );
}

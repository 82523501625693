import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Modal, ToggleButtonGroup } from 'react-bootstrap';
import { ToggleButton } from 'react-bootstrap';
import JobList from './components/JobList';
import jobStatuses from '../../actions/jobStatuses';
import JobDetails from './components/JobDetails';
import dateRanges from '../../actions/dateRanges';

export default function Jobs({ status }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [reload, setReload] = useState();
  const [show, setShow] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(-1);
  const [jobId, setJobId] = useState(
    location.hash && location.hash.length > 1
      ? location.hash.substring(1, location.hash.length)
      : null
  );

  const resolveTitle = (status) => {
    if (status === jobStatuses.COMPLETE) {
      return 'Completed jobs';
    }
    if (status === jobStatuses.INPROGRESS) {
      return 'Jobs in progress';
    }
    if (status === jobStatuses.INVOICED) {
      return 'Invoiced jobs';
    }
    if (status === jobStatuses.NEEDSINVOICING) {
      return 'Jobs needing invoicing';
    }
    if (status === jobStatuses.NEEDSPRICING) {
      return 'Jobs needing pricing';
    }
    if (status === jobStatuses.NEEDSRESCHEDULING) {
      return 'Jobs needing scheduling';
    }
    if (status === jobStatuses.NEEDSSECONDVISIT) {
      return 'Jobs needing second visit';
    }
    if (status === jobStatuses.READY) {
      return 'Jobs ready to start';
    }
    if (status === jobStatuses.UNASSIGNED) {
      return 'Unassigned jobs';
    }
    return 'Jobs';
  };

  const handleClose = () => {
    setReload(new Date());
    navigate(-1);
  };

  React.useEffect(() => {
    if (location.hash !== jobId) {
      setJobId(location.hash);
      if (location.hash && location.hash.length > 1) {
        setJobId(location.hash.substring(1, location.hash.length));
      } else {
        setJobId(null);
      }
    }
  }, [location.hash, jobId]);

  React.useEffect(() => {
    if (jobId && jobId.length > 1) {
      setShow(true);
    } else {
      console.log('no job id');
      setShow(false);
    }
  }, [jobId]);

  const handleDateRangeChange = (val) => {
    const params = new URLSearchParams(location.search);
    if (val === dateRanges.TODAY) {
      params.set(`s_dateRange`, 'today');
    } else if (val === dateRanges.TOMORROW) {
      params.set(`s_dateRange`, 'tomorrow');
    } else if (val === dateRanges.YESTERDAY) {
      params.set(`s_dateRange`, 'yesterday');
    } else if (val === dateRanges.THISWEEK) {
      params.set(`s_dateRange`, 'thisweek');
    } else if (val === dateRanges.NEXTWEEK) {
      params.set(`s_dateRange`, 'nextweek');
    } else if (val === dateRanges.LASTWEEK) {
      params.set(`s_dateRange`, 'lastweek');
    } else if (val === dateRanges.BEFORENOW) {
      params.set(`s_dateRange`, 'beforenow');
    } else {
      params.delete(`s_dateRange`);
    }
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
  };
  React.useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const val = params.get('s_dateRange');
      if (val === 'today') {
        setSelectedDateRange(dateRanges.TODAY);
      } else if (val === 'tomorrow') {
        setSelectedDateRange(dateRanges.TOMORROW);
      } else if (val === 'yesterday') {
        setSelectedDateRange(dateRanges.YESTERDAY);
      } else if (val === 'thisweek') {
        setSelectedDateRange(dateRanges.THISWEEK);
      } else if (val === 'nextweek') {
        setSelectedDateRange(dateRanges.NEXTWEEK);
      } else if (val === 'lastweek') {
        setSelectedDateRange(dateRanges.LASTWEEK);
      } else if (val === 'beforenow') {
        setSelectedDateRange(dateRanges.BEFORENOW);
      } else {
        setSelectedDateRange(-1);
      }
    } else {
      setSelectedDateRange(-1);
    }
  }, [location.search, setSelectedDateRange]);

  return (
    <>
      <div className="page-content jobs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-md-12 col-xl-3">
              <h1>
                <i className="fa-light fa-hammer me-2"></i>
                {resolveTitle(status)}
              </h1>
            </div>

            <div className="col-6 col-md-12 col-xl-9 align-items-start">
              <div className="d-flex align-items-center justify-content-end">
                <h2 className="m-0">
                  <i className="fa-light fa-calendar-days"></i>
                </h2>
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  value={selectedDateRange}
                  defaultValue={selectedDateRange}
                  className="d-none d-md-inline-flex mb-2"
                  onChange={handleDateRangeChange}
                >
                  <ToggleButton id="tbg-radio-0" value={-1} variant="secondary">
                    All
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-radio-1"
                    value={dateRanges.TODAY}
                    variant="secondary"
                  >
                    Today
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-radio-2"
                    value={dateRanges.TOMORROW}
                    variant="secondary"
                  >
                    Tomorrow
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-radio-3"
                    value={dateRanges.YESTERDAY}
                    variant="secondary"
                  >
                    Yesterday
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-radio-4"
                    value={dateRanges.THISWEEK}
                    variant="secondary"
                  >
                    This week
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-radio-5"
                    value={dateRanges.NEXTWEEK}
                    variant="secondary"
                  >
                    Next week
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-radio-6"
                    value={dateRanges.LASTWEEK}
                    variant="secondary"
                  >
                    Last week
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-radio-7"
                    value={dateRanges.BEFORENOW}
                    variant="secondary"
                  >
                    Before today
                  </ToggleButton>
                </ToggleButtonGroup>
                <Dropdown className="d-block d-md-none">
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Select to show
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        handleDateRangeChange(1);
                      }}
                    >
                      Today
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleDateRangeChange(2);
                      }}
                    >
                      Tomorrow
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleDateRangeChange(3);
                      }}
                    >
                      This week
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleDateRangeChange(4);
                      }}
                    >
                      Next week
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleDateRangeChange(5);
                      }}
                    >
                      Last week
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleDateRangeChange(6);
                      }}
                    >
                      All
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <JobList status={status} location={location} reload={reload} />

          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-dialog-centered modal-xl modal-fullscreen-xl-down"
          >
            <JobDetails id={jobId} key={jobId} onClose={handleClose} />
          </Modal>
        </div>
      </div>
    </>
  );
}

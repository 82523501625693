import React, { useEffect, useState } from 'react';

const LoadingOverlay = ({ message, inPanel, isError, delay }) => {
  const [show, setShow] = useState(false);
  const _delay = delay === undefined ? 0.5 : delay;

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), _delay * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  return show ? (
    <div className="loader overlay">
      <>
        <div className="loading-img">
          <img
            src={
              isError
                ? '/content/img/alaska_loader_error.png'
                : inPanel
                ? '/content/img/alaska_loader2.gif'
                : '/img/loader-overlay.gif'
            }
            alt="loading"
            className="img-responsive"
            width="100px"
            height="100px"
          />
        </div>
        <span>{message || 'LOADING'}</span>
      </>
    </div>
  ) : (
    ''
  );
};

export default LoadingOverlay;

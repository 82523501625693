import React, { useState, useEffect } from 'react';
import {
  saveJobInvoiceNumber,
  saveJobFinance,
} from '../../../actions/jobActions';
import { getAssignableUsers } from '../../../actions/userActions';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

// https://reactdatepicker.com/
// https://github.com/Hacker0x01/react-datepicker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loading from '../../common/Loading';
import { userHasPermission } from '../../auth/authUtils';
import permissionTypes from '../../auth/permissionTypes';

export default function EditFinance({ job, currentUser, done }) {
  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsSaving(true);

    if (userHasPermission(currentUser, permissionTypes.PRICING)) {
      saveJobFinance(
        job.id,
        Number(data.costOfMaterials),
        Number(data.costOfLabor),
        Number(data.totalDurationMinutes)
      ).then(() => {
        if (userHasPermission(currentUser, permissionTypes.INVOICING)) {
          saveJobInvoiceNumber(job.id, data.invoiceNumber).then(() => {
            toast.success('Saved');
            done();
            setIsSaving(false);
          });
        } else {
          toast.success('Saved');
          done();
          setIsSaving(false);
        }
      });
    } else if (userHasPermission(currentUser, permissionTypes.INVOICING)) {
      saveJobInvoiceNumber(job.id, data.invoiceNumber).then(() => {
        toast.success('Saved');
        done();
        setIsSaving(false);
      });
    } else {
      toast.success('Saved');
      done();
      setIsSaving(false);
    }
  };

  return job && !isSaving ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-light fa-circle-check me-2"></i>Job finance
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid g-0">
          {userHasPermission(currentUser, permissionTypes.PRICING) && (
            <>
              <div className="form-group row g-0 mb-3">
                <label className="col-4">
                  <i className="fa-light fa-user-large me-2 blue"></i>Total
                  duration minutes
                </label>
                <div className="col-8">
                  <input
                    type="number"
                    autoFocus
                    required
                    autoComplete="off"
                    className="form-control"
                    placeholder="totalDurationMinutes"
                    defaultValue={job.totalDurationMinutes}
                    {...register('totalDurationMinutes', {})}
                  />
                  {errors.totalDurationMinutes && (
                    <span className="field-validation-error">Required</span>
                  )}
                </div>
              </div>
              <div className="form-group row g-0 mb-3">
                <label className="col-4">
                  <i className="fa-light fa-user-large me-2 blue"></i>Cost of
                  materials
                </label>
                <div className="col-8">
                  <input
                    type="number"
                    required
                    autoComplete="off"
                    className="form-control"
                    placeholder="costOfMaterials"
                    defaultValue={job.costOfMaterials}
                    {...register('costOfMaterials', {})}
                  />
                  {errors.costOfMaterials && (
                    <span className="field-validation-error">Required</span>
                  )}
                </div>
              </div>
              <div className="form-group row g-0 mb-3">
                <label className="col-4">
                  <i className="fa-light fa-user-large me-2 blue"></i>Cost of
                  labor
                </label>
                <div className="col-8">
                  <input
                    type="number"
                    required
                    autoComplete="off"
                    className="form-control"
                    placeholder="costOfLabor"
                    defaultValue={job.costOfLabor}
                    {...register('costOfLabor', {})}
                  />
                  {errors.costOfLabor && (
                    <span className="field-validation-error">Required</span>
                  )}
                </div>
              </div>
            </>
          )}

          {userHasPermission(currentUser, permissionTypes.INVOICING) && (
            <>
              <div className="form-group row g-0 mb-3">
                <label className="col-4">
                  <i className="fa-light fa-user-large me-2 blue"></i>Invoice
                  number
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="invoiceNumber"
                    defaultValue={job.invoiceNumber}
                    {...register('invoiceNumber', {})}
                  />
                </div>
              </div>
            </>
          )}

          <div className="row">
            <div className="col d-flex justify-content-end">
              <button
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary"
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  ) : (
    <Loading></Loading>
  );
}

import React, { useState } from 'react';
import { loadCustomers } from '../../../actions/customerActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../common/Table';
import { CommaNumber_NoDecimal } from '../../../util/formatter';
import Loading from '../../common/Loading';
import useTableSettings from '../../../hooks/useTableSettings';

function CustomerList({ dateRange, status, location, reload }) {
  const [visibleColumns] = useState([
    'customerName',
    'totalJobs',
    'openJobs',
    'completeJobs',
  ]);

  const [customers, setCustomers] = useState();
  const params = new URLSearchParams(location.search);
  const [tableSettings, setTableSettings, filterChange, isLoading] =
    useTableSettings(
      'customerlist-settings',
      getTableSettingsDefaults('customerName', location.search, 'asc'),
      {
        status: status,
      },
      loadCustomers,
      setCustomers,
      reload,
      'Failed to load customers',
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'customerName',
      Label: 'Customer',
      width: '25%',

      Render: (customer) => (
        <Link to={'#' + customer.id}>{customer.customerName}</Link>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key="search"
            Value={params.get(`f_search`) || ''}
            onChange={(value) => filterChange('search', value)}
          />
        ),
      },
    },

    {
      Key: 'openJobs',
      Label: 'Open jobs',
      width: '25%',
      Render: (customer) => <>{CommaNumber_NoDecimal(customer.openJobs)}</>,
    },
    {
      Key: 'completeJobs',
      Label: 'Complete jobs',
      width: '25%',
      Render: (customer) => <>{CommaNumber_NoDecimal(customer.completeJobs)}</>,
    },
    {
      Key: 'totalJobs',
      Label: 'Total jobs',
      width: '25%',
      Render: (customer) => <>{CommaNumber_NoDecimal(customer.totalJobs)}</>,
    },
  ];

  // filter
  let columns = visibleColumns.map((x) =>
    columnsConfig.find((y) => y.Key === x)
  );

  // return a table
  return customers ? (
    <div>
      <Table
        columns={columns}
        rows={customers.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={customers.totalRowCount}
        filteredRowCount={customers.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
      />
    </div>
  ) : (
    <Loading></Loading>
  );
}

export default CustomerList;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCustomers } from '../../actions/customerActions';
import { saveUser } from '../../actions/userActions';
import { useForm } from 'react-hook-form';
import UserForm from './components/UserForm';
import { toast } from 'react-toastify';

import { getAllRoles, addMember } from '../../actions/roleActions';
import { Roles } from './components/Roles';
export default function AddUser() {
  const [user] = useState();
  const navigate = useNavigate();
  const [roles, setRoles] = useState();
  const [userRoles, setUserRoles] = useState([]);

  const [customers, setCustomers] = useState();
  useEffect(() => {
    if (!customers) {
      getCustomers().then((d) => {
        setCustomers(d);
      });
    }
  }, [customers]);

  useEffect(() => {
    if (!roles) {
      getAllRoles().then((d) => {
        setRoles(d.data);
      });
    }
  }, [roles]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    data.customerId = data.customerId !== '' ? data.customerId : null;
    saveUser(data)
      .then((userId) => {
        let requests = userRoles.map((id) => addMember(id, userId));
        Promise.all(requests).then(() => {
          toast.success('User invited');
          navigate('/users');
        });
      })
      .catch(() => {
        toast.error('Failed to invite user');
      });
  };
  const toggleRoles = (id, checked) => {
    setUserRoles(
      checked ? [...userRoles, id] : userRoles.filter((x) => x !== id)
    );
  };
  return (
    <div className="page-content addUser">
      <div className="container-fluid">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col">
              <h1 className="d-flex align-items-center justify-content-between">
                <span>
                  <i className="fa-light fa-circle-plus me-2"></i>Add user
                </span>
                <input type="submit" className="btn btn-primary float-end" />
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-8">
              <div className="card">
                <div className="card-body">
                  <h2>User Details</h2>
                  <UserForm
                    user={user}
                    register={register}
                    errors={errors}
                    customers={customers}
                  ></UserForm>
                  {roles && (
                    <Roles roles={roles} onChange={toggleRoles}></Roles>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <input type="submit" className="btn btn-primary float-end" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

import AddRoleStep1 from './components/AddRoleStep1';

export default function AddRole() {
  return (
    <>
      <div className="page-content addRole">
        <div className="container-fluid">
          <AddRoleStep1></AddRoleStep1>
        </div>
      </div>
    </>
  );
}

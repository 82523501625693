import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Gravatar from 'react-gravatar';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteCustomer,
  getCustomerById,
} from '../../../actions/customerActions';
import {
  CommaNumber_NoDecimal,
  DateAndTime_AsString,
  Date_AsString,
  FriendlyTimeFromMinutes,
  statusText,
} from '../../../util/formatter';
import Loading from '../../common/Loading';
import ConfirmDialog from '../../jobs/components/ConfirmDialog';
import EditCustomer from './EditCustomer';

export default function CustomerDetails({ id, onDelete, onReload }) {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [reload, setReload] = useState();

  useEffect(() => {
    if (id) {
      // Get the customer
      getCustomerById(id).then((j) => {
        setCustomer(j);
      });
    }
  }, [id, setCustomer, reload]);

  let handleDelete = () => {
    setShowDelete(true);
  };

  let handleOnTrue = () => {
    deleteCustomer(id).then(() => {
      setShowDelete(false);
      onDelete();
    });
  };

  let handleOnDone = () => {
    // reload
    setShowEdit(false);
    setReload(new Date());
    onReload();
  };

  return (
    <>
      {customer ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-light fa-building me-2"></i> Customer Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid customer-details g-0 ">
              <h2 className="no-border p-0">{customer.customerName}</h2>

              <div className="row ">
                <div className="col-12 col-lg-4">
                  <div className="card white mb-3 mb-md-0">
                    <div
                      className="card-body"
                      onClick={() =>
                        navigate(
                          `/jobs/list/all?s_customer=${customer.customerId}`
                        )
                      }
                    >
                      <h3 className="white">
                        Total Jobs <i className="fa-light fa-hammer white"></i>
                      </h3>
                      <span className="Jobs-num"> {customer.totalJobs}</span>
                      <span>Jobs</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card blue mb-3 mb-lg-0">
                    <div
                      className="card-body"
                      onClick={() =>
                        navigate(
                          `/jobs/list/all?s_maxStatus=NeedsRescheduling&s_customer=${customer.customerId}`
                        )
                      }
                    >
                      <h3 className="white">
                        Jobs in progress{' '}
                        <i className="fa-light fa-hourglass blue"></i>
                      </h3>
                      <span className="Jobs-num">
                        {' '}
                        {CommaNumber_NoDecimal(customer.openJobs)}
                      </span>
                      <span>Jobs</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card green  mb-3 mb-lg-0">
                    <div
                      className="card-body"
                      onClick={() =>
                        navigate(
                          `/jobs/list/all?s_minStatus=Complete&s_customer=${customer.customerId}`
                        )
                      }
                    >
                      <h3 className="white">
                        Complete Jobs{' '}
                        <i className="fa-light fa-circle-check green"></i>
                      </h3>
                      <span className="Jobs-num">
                        {' '}
                        {CommaNumber_NoDecimal(customer.completeJobs)}
                      </span>
                      <span>Jobs</span>
                    </div>
                  </div>
                </div>
                <div className="col d-flex justify-content-between mt-3">
                  <button
                    onClick={handleDelete}
                    className="btn btn-danger"
                    disabled={isSaving}
                  >
                    <i className="fa-solid fa-trash-can"></i> Delete
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => setShowEdit(true)}
                    disabled={isSaving}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal
            show={showDelete}
            onHide={() => setShowDelete(false)}
            dialogClassName="modal-dialog-centered modal-md "
          >
            <ConfirmDialog
              icon=""
              title="Delete customer?"
              message="Are you sure you want to delete this customer?"
              trueText={'Yes, delete'}
              falseText={'No'}
              onTrue={handleOnTrue}
              onFalse={() => setShowDelete(false)}
            />
          </Modal>
          <Modal
            show={showEdit}
            onHide={() => setShowEdit(false)}
            dialogClassName="modal-dialog-centered modal-md "
          >
            <EditCustomer customer={customer} onDone={handleOnDone} />
          </Modal>
        </>
      ) : (
        <Loading></Loading>
      )}
    </>
  );
}

import { UserManager } from 'oidc-client';

const config = {
  authority:
    window.globalConfig?.oidcAuthority || process.env.REACT_APP_OIDC_AUTHORITY,
  client_id:
    window.globalConfig?.oidcClientId || process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri:
    window.globalConfig?.oidcRedirectUri ||
    process.env.REACT_APP_OIDC_REDIRECT_URI,
  response_type: 'id_token token',
  scope: 'openid profile api',
  post_logout_redirect_uri:
    window.globalConfig?.oidcPostLogoutRedirectUri ||
    process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: true,
  silent_redirect_uri:
    window.globalConfig?.oidcSilentRedirectUri ||
    process.env.REACT_APP_OIDC_SILENT_REDIRECT_URI,
};
const userManager = new UserManager(config);

export function getUser() {
  return userManager.getUser();
}

export function signinRedirect(path) {
  return userManager.signinRedirect({ state: path });
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  getUser().then((u) => {
    const hint = u.id_token;
    userManager.clearStaleState();
    userManager.removeUser();
    return userManager.signoutRedirect({ id_token_hint: hint });
  });
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

export function impersonate(id) {
  return userManager.signinSilent({ acr_values: `impersonate:${id}` });
}

export function signinSilentCallback() {
  return userManager.signinSilentCallback().then(() => {
    return getUser();
  });
}

export default userManager;

export default function ApiFailure() {
  return (
    <div className="error-page">
      <img src={'/img/logo.svg'} alt="Bellcorp" />
      <h2>Api Failure</h2>
      <a href="/" className="btn btn-primary mt-3">
        <i className="fas fa-chevron-left"></i> Take me back to WMS
      </a>
    </div>
  );
}

import * as rolesApi from '../api/rolesApi';

export function loadRoles({ filters, _scopes, _sort, _order, _page, _limit }) {
  return rolesApi.getRoles(filters, _scopes, _sort, _order, _page, _limit);
}

export function getAllRoles() {
  return rolesApi.getAllRoles();
}

export function getRoleById(id) {
  return rolesApi.getRole(id);
}

export function saveRole(role) {
  return rolesApi.saveRole(role);
}

export function deleteRole(id) {
  return rolesApi.deleteRole(id);
}

export function deleteMember(id, userId) {
  return rolesApi.deleteMember(id, userId);
}
export function addMember(id, userId) {
  return rolesApi.addMember(id, userId);
}
